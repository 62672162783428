import React, { useState, useEffect } from "react";
import "./story-header.component.scss";
import { StoryContainer } from "../../story.container";

/*
 * ヘッダーコンポーネント
 */
export const StoryHeaderComponent: React.FC = () => {
  const storyContainer = StoryContainer.useContainer();

  //再生ボタン
  const onClickReloadButton = () => {
    if (storyContainer.videoEl.current) {
      storyContainer.videoEl.current.currentTime = 0;
      storyContainer.setIsVideoEnd(false);
      storyContainer.switchPlayVideo();
    }
  };

  const onClickEndButton = () => {
    storyContainer.setIsEndDialog(true);
  };

  useEffect(() => {
    if (storyContainer.isFullScreen) {
      return;
    }
    //プログレスバーの設定
    if (storyContainer.isPlayVideo) {
      const ProgressBar = require("progressbar.js");
      let progressBar = new ProgressBar.Circle("#progressbar", {
        strokeWidth: 8,
        easing: "linear",
        duration: 1000,
        color: "white",
        trailColor: "#757575",
        trailWidth: 8,
        svgStyle: null,
      });

      //アニメーション
      setInterval(() => {
        let progress: number = 0;
        if (storyContainer.videoEl.current) {
          const current: HTMLVideoElement = storyContainer.videoEl.current;
          if (current.duration !== undefined && current.duration > 0) {
            progress = current.currentTime / current.duration;
          }
        }
        progressBar.set(progress);
      }, 50);
    }
  }, [storyContainer.isPlayVideo]);

  return (
    <div className="story-header">
      {!storyContainer.isFullScreen && (
        <div className="columns is-gapless is-mobile">
          <div className="column">
            {storyContainer.isPlayVideo && !storyContainer.isVideoEnd && (
              <div id="progressbar"></div>
            )}
            {(!storyContainer.isPlayVideo || storyContainer.isVideoEnd) && (
              <div>
                {storyContainer.isReplayEnabled && (
                  <div
                    className="reload has-text-left"
                    onClick={onClickReloadButton}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/reload.png`}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="column is-two-thirds">
            {storyContainer.story?.scoreEnable && (
              <div className="score is-size-4 has-text-white has-text-centered has-text-weight-bold">
                {storyContainer.story?.scoreName}: {storyContainer.scorePoint}
              </div>
            )}
          </div>
          <div className="column">
            {(!storyContainer.isPlayVideo || storyContainer.isVideoEnd) && (
              <div className="close has-text-right" onClick={onClickEndButton}>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/close.png`}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
