import React from "react";
import { StoryContainer } from "../../story.container";

export const StorySoundComponent: React.FC = () => {
  const storyContainer = StoryContainer.useContainer();

  return (
    <div>
      <audio
        ref={storyContainer.addPointAudioRef}
        preload="auto"
        src={`${process.env.PUBLIC_URL}/assets/sound/se/getscore.mp3`}
      />
      <audio
        ref={storyContainer.subtractPointAudioRef}
        preload="auto"
        src={`${process.env.PUBLIC_URL}/assets/sound/se/losescore.mp3`}
      />
    </div>
  );
};
