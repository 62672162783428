import * as firebase from "firebase"
import { docData, collectionData } from 'rxfire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserMediaEntity } from '@6cuts/@dto/write.user';



const isUserMediaEntity = (arg: any) => {
    return arg.id !== undefined
}

export class FSReadOwnUserMediaRepository {

    static collectionName(data: { userId: string }): string {
        return `read.own_user/${data.userId}/media`
    }

    static watch(
        data: {
            userId: string,
            mediaId: string,
        }
    ): Observable<UserMediaEntity | null> {
        const docRef = firebase.firestore()
            .collection(this.collectionName({ userId: data.userId }))
            .doc(data.mediaId) as firebase.firestore.DocumentReference<UserMediaEntity>

        return docData<UserMediaEntity>(docRef)
            .pipe(
                map((entity: UserMediaEntity) => {
                    if (!isUserMediaEntity(entity)) {
                        return null
                    }

                    const _entity: UserMediaEntity = {
                        id: entity.id,
                        userId: entity.userId,
                        status: entity.status,
                        memo: entity.memo === undefined ? "" : entity.memo,
                        color: entity.color === undefined ? "red" : entity.color,
                        duration: entity.duration,
                        created: entity.created,
                        updated: entity.updated,
                    }

                    return _entity
                })
            )
    }

    static watchList(
        data: {
            userId: string
        }
    ): Observable<UserMediaEntity[]> {
        const docRef = firebase.firestore()
            .collection(this.collectionName({ userId: data.userId }))

        return collectionData<UserMediaEntity>(docRef)
            .pipe(
                map((entityList: UserMediaEntity[]) => {
                    return entityList.filter((entity: UserMediaEntity) => {
                        return isUserMediaEntity(entity)
                    }).map((entity: UserMediaEntity) => {
                        const _entity: UserMediaEntity = {
                            id: entity.id,
                            userId: entity.userId,
                            status: entity.status,
                            memo: entity.memo === undefined ? "" : entity.memo,
                            color: entity.color === undefined ? "red" : entity.color,
                            duration: entity.duration,
                            created: entity.created,
                            updated: entity.updated,
                        }

                        return _entity
                    })
                })
            )
    }
}