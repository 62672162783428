
import * as firebase from "firebase";
import { WriteStoryChapterEntity, ChapterActionType, ChapterActionSelect, ChapterActionKeyword } from "@6cuts/@dto/write.story";

export class FSWriteStoryChapterRepository {
    static readonly COLLECTION_NAME_FIRST: string = "write.story";
    static readonly COLLECTION_NAME_SECOND: string = "write.chapter";

    static getBlankChapter(): WriteStoryChapterEntity {
        const now = firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp;
        const chapter: WriteStoryChapterEntity = {
            id: "",
            chapterId: "",
            sentenceIdList: [],
            actionType: ChapterActionType.NONE,
            explanation: "",
            select: [],
            keyword: {
                answer: [],
                incorrect: {
                    text: "",
                    point: 0,
                    transitionId: "",
                },
            },
            created: now,
            updated: now,
        };
        return chapter;
    }

    static getActionSelectChapter(): ChapterActionSelect {
        const actionSelect: ChapterActionSelect = {
            text: "",
            point: 0,
            selectPointLimit: 0,
            transitionId: "",
        };
        return actionSelect;
    }

    static getActionKeywordChapter(): ChapterActionKeyword {
        const actionKeyword: ChapterActionKeyword = {
            text: "",
            point: 0,
            transitionId: "",
        };
        return actionKeyword;
    }

    static createChapter(data: {
        storyId: string;
        chapter: WriteStoryChapterEntity;
    }): Promise<string> {
        const now = firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp;
        return firebase.firestore()
            .collection(this.COLLECTION_NAME_FIRST)
            .doc(data.storyId)
            .collection(this.COLLECTION_NAME_SECOND)
            .add({
                chapterId: data.chapter.chapterId,
                sentenceIdList: data.chapter.sentenceIdList,
                actionType: data.chapter.actionType as ChapterActionType,
                explanation: data.chapter.explanation,
                select: data.chapter.select,
                keyword: data.chapter.keyword,
                created: now,
                updated: now,
            })
            .then(
                (
                    ref: firebase.firestore.DocumentReference<
                        firebase.firestore.DocumentData
                    >
                ) => {
                    return ref.id;
                }
            );
    }

    static updateChapter(data: {
        storyId: string;
        chapter: WriteStoryChapterEntity;
    }): Promise<void> {
        const now = firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp;

        console.log(data.storyId);
        console.log(data.chapter.id);
        return firebase.firestore()
            .collection(this.COLLECTION_NAME_FIRST)
            .doc(data.storyId)
            .collection(this.COLLECTION_NAME_SECOND)
            .doc(data.chapter.id)
            .update({
                id: data.chapter.id,
                chapterId: data.chapter.chapterId,
                sentenceIdList: data.chapter.sentenceIdList,
                actionType: data.chapter.actionType,
                explanation: data.chapter.explanation,
                select: data.chapter.select,
                keyword: data.chapter.keyword,
                created: data.chapter.created,
                updated: now,
            });
    }
}
