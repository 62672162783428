import React, { useState, useEffect } from "react";
import styles from "./management-page.module.scss";
import { AppContainer } from "../../../containers/app/app.container";
import { useHistory } from "react-router-dom";
import { NaviComponent } from "../../common/navi/navi";
import { FSReadStoryRepository } from "@6cuts/public-repository/read.story";
import { ReadStoryEntity } from "@6cuts/@dto/read.story";
import { FSWriteStoryRepository } from "@6cuts/public-repository/write.story";
import { WriteStoryEntity } from "@6cuts/@dto/write.story";
import { CSStoryImageRepository } from "@6cuts/storage/story/image";

export const StoryManagementPageComponent: React.FC = () => {
  const appContainer = AppContainer.useContainer();
  const userId: string = appContainer.user !== null ? appContainer.user.id : "";
  const history = useHistory();
  const [storyList, setStoryList] = useState<JSX.Element[]>([]);

  useEffect(() => {
    if (userId == "") {
      return;
    }
    const subscription = FSReadStoryRepository.watchStoryList({
      userId: userId,
    }).subscribe((storyList: ReadStoryEntity[] | null) => {
      console.log(storyList);
      let list: JSX.Element[] = [];
      storyList?.forEach((story: ReadStoryEntity) => {
        list.push(<StoryContentCellComponent story={story} />);
      });
      setStoryList(list);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [userId]);

  const onClickCreateStoryHandler = () => {
    const blankStory: WriteStoryEntity = FSWriteStoryRepository.getBlankStory(userId);
    FSWriteStoryRepository.createStory(blankStory).then((id: string) => {
      history.push(`/story/edit/${id}`);
    });
  };

  return (
    <div className={styles.page}>
      <NaviComponent />
      <div className="container">
        <div className="header">
          <div className="label is-large">ストーリー管理</div>
          <u>
            <div className="has-text-right" onClick={onClickCreateStoryHandler}>
              新しく作成
            </div>
          </u>
        </div>
        <div className="container">
          <ul>{storyList}</ul>
        </div>
      </div>
    </div>
  );
};

interface StoryContentCellProps {
  story: ReadStoryEntity;
}

export const StoryContentCellComponent: React.FC<StoryContentCellProps> = (
  props
) => {
  const history = useHistory();
  const [imageUrl, setImageUrl] = useState("");
  const onClickStoryHandler = () => {
    history.push(`/story/edit/${props.story.id}`);
  };

  useEffect(() => {
    if (props.story.thumbnailImage !== "") {
      CSStoryImageRepository.getStoryThumbnailImageUrl({
        storyId: props.story.id,
      }).then((imageUrl: string) => {
        setImageUrl(imageUrl);
      });
    } else {
    }
  }, [props.story]);

  return (
    <div className={styles.chapter}>
      <div
        className="contents columns is-mobile is-gapless"
        onClick={onClickStoryHandler}
      >
        <div className="thumbnail colum">
          {imageUrl === "" && <div className="black-image"></div>}
          {imageUrl !== "" && <img src={imageUrl} />}
        </div>
        <div className="details colum">
          {props.story.published && <label className="published">公開中</label>}
          <label className="story-title">
            {props.story.title !== "" ? props.story.title : "タイトル未設定"}
          </label>
        </div>
      </div>
    </div>
  );
};
