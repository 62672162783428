import { ReadStoryEntity } from "@6cuts/@dto/read.story";
import React, { useState } from "react";
import { FSWriteStoryRepository } from "@6cuts/public-repository/write.story";
import {
  YesNoAlertComponent,
  OkAlertComponent,
} from "../../../common/alert/alert";
import styles from "./edit-menu.module.scss";
import { useHistory } from "react-router-dom";
import { LoadingModalComponent } from "../../../common/loading/loading";

interface StoryEditMenuProps {
  story: ReadStoryEntity | null;
}

export const StoryEditMenuComponent: React.FC<StoryEditMenuProps> = (props) => {
  const history = useHistory();
  const [isReleaseAlert, setIsReleaseAlert] = useState<boolean>(false);
  const [isErrorAlert, setIsErrorAlert] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isReleaseLoading, setIsReleaseLoading] = useState<boolean>(false);

  //公開ボタンハンドラー
  const onClickReleaseHandler = () => {
    if (props.story?.thumbnailImage === "") {
      setErrorMessage("TOP画像を設定してください");
      setIsErrorAlert(true);
      return;
    }
    setIsReleaseAlert(true);
  };

  const onClickReleaseAlertHandler = () => {
    setIsReleaseLoading(true);
    FSWriteStoryRepository.publish({ storyId: props.story!.id }).then((_) => {
      setIsReleaseAlert(false);
    });
  };

  const onClickSettingRuleHandler = () => {
    history.push(`/story/edit/rule/${props.story?.id}`);
  };

  //タイトル変更ボタンハンドラー
  const onClickChapterListHandler = () => {
    history.push(`/story/edit/chapter-list/${props.story?.id}`);
  };
  return (
    <div className={styles.component}>
      <div className="menu">
        <div className="normal has-text-centered">
          <button
            className="button is-large"
            onClick={onClickSettingRuleHandler}
          >
            ルール設定
          </button>
        </div>
        <div className="normal has-text-centered">
          <button
            className="button is-large"
            onClick={onClickChapterListHandler}
          >
            チャプターリスト
          </button>
        </div>
        <div className="release has-text-centered">
          <button
            className="button is-rounded is-large has-text-white is-success"
            onClick={onClickReleaseHandler}
          >
            公開する
            <br />
            ※公開後は編集できません※
          </button>
        </div>
      </div>
      <YesNoAlertComponent
        title="確認"
        message="公開しますか？"
        yesCallback={onClickReleaseAlertHandler}
        noCallback={() => {
          setIsReleaseAlert(false);
        }}
        isOpen={isReleaseAlert}
      />
      <OkAlertComponent
        title="エラー"
        message={errorMessage}
        okCallback={() => {
          setIsErrorAlert(false);
        }}
        isOpen={isErrorAlert}
      />
      <LoadingModalComponent
        title="公開中"
        isOpen={isReleaseLoading}
        second={10000}
        timerCallback={() => {
          setIsReleaseLoading(false);
        }}
      />
    </div>
  );
};
