import * as firebase from "firebase";
import { authState } from "rxfire/auth";
import { take } from "rxjs/operators";


export class AuthService {
         static async isAuthenticated(): Promise<boolean> {
           return await authState(firebase.auth())
             .pipe(take(1))
             .toPromise()
             .then((user: firebase.User) => {
               return user !== null;
             });
         }

         static signInWithAnonymous(): Promise<firebase.User | null> {
           return firebase
             .auth()
             .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
             .then(() => {
               return firebase
                 .auth()
                 .signInAnonymously()
                 .then((result: firebase.auth.UserCredential) => {
                   if (result.user !== null) {
                     return result.user;
                   } else {
                     return null;
                   }
                 })
                 .catch((error) => {
                   console.log(`failed to sign in : ${error}`);
                   console.log(error);
                   throw error;
                 });
             });
         }

         static signInWithTwitter(): Promise<void> {
           const provider = new firebase.auth.TwitterAuthProvider();
           return firebase.auth().signInWithRedirect(provider);
         }

         static checkRedirectResult(): Promise<firebase.User | null> {
           return firebase
             .auth()
             .getRedirectResult()
             .then((result: firebase.auth.UserCredential) => {
               if (result.user !== null) {
                 return result.user;
               } else {
                 return null;
               }
             })
             .catch((error) => {
               console.log(`failed to sign in : ${error}`);
               console.log(error);
               throw error;
             });
         }

         static signUpWithEmailAndPassword(data: {
           email: string;
           password: string;
         }): Promise<firebase.User> {
           return firebase
             .auth()
             .createUserWithEmailAndPassword(data.email, data.password)
             .then((credential: firebase.auth.UserCredential) => {
               if (credential.user === null) {
                 throw Error(
                   "アカウントの作成に失敗しました。通信状況をお確かめの上、再度お試し下さい。"
                 );
               } else {
                 return credential.user;
               }
             })
             .catch((error) => {
               throw error;
             });
         }

         static signInWithEmailAndPassword(data: {
           email: string;
           password: string;
         }): Promise<firebase.User> {
           return firebase
             .auth()
             .signInWithEmailAndPassword(data.email, data.password)
             .then((credential: firebase.auth.UserCredential) => {
               if (credential.user === null) {
                 throw Error("Invalid email address or password.");
               } else {
                 return credential.user;
               }
             })
             .catch((error) => {
               throw error;
             });
         }

         static sendPasswordResetEmail(data: {
           email: string;
         }): Promise<void> {
             return firebase
               .auth()
               .sendPasswordResetEmail(data.email)
               .catch((error) => {
                 throw error;
               });
         }

         static signOut(): Promise<void> {
           return firebase.auth().signOut();
         }
       }