import { useState, useEffect, useRef } from "react";

import * as firebase from "firebase";

import { createContainer } from "unstated-next";
import { ReadOwnUserEntity } from "@6cuts/@dto/read.own-user";
import { FSReadOwnUserRepository } from "@6cuts/public-repository/read.own_user";
import { Gender } from "@6cuts/@dto/write.user";

const useAppContainer = (initialState = "") => {
  const [userId, setUserId] = useState<string | null>(null);
  const [user, setUser] = useState<ReadOwnUserEntity | null>(null);

  /* UserIDを監視してユーザ情報を取得する */
  useEffect(() => {
    // UserIDがnullだったらUserもnullにして終了
    if (userId === null) {
      setUser(null);
      return;
    }

    // User情報を取得
    const subscription = FSReadOwnUserRepository.watchUser({
      userId: userId,
    }).subscribe((user: ReadOwnUserEntity | null) => {
      // ユーザ未登録の場合は初期データをセット
      if (user === null) {
        const now = firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp
        setUser({
          id: userId,
          nickname: "",
          gender: Gender.unknown,
          birthday: "",
          isExistIcon: false,
          created: now,
          updated: now,
        });
      } else {
        setUser(user);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [userId]);

  return {
    userId,
    user,
    setUserId,
    setUser,
  };
};

export const AppContainer = createContainer(useAppContainer);
