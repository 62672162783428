import React, { useState } from "react";
import { StoryContainer } from "../story.container";
import styles from "./story-top-component.module.scss";
// import { StarComponent } from "../../common/star/star-component";
// import { PurchaseComponent } from "../../common/purchase/component";

/*
 * ストーリートップ
 */
export const StoryTopComponent: React.FC = () => {
  const storyContainer = StoryContainer.useContainer();

  const [isModalShow, setIsModalShow] = useState<boolean>(false);

  const onClickTrial = () => {
    console.log("trial");
    storyContainer.setIsStart(true);
  };

  const onClickPurchase = () => {
    // if (storyContainer.story?.id === "VBEr5g979L81V9nveP3V") {
    //   setIsModalShow(true);
    // }
  };

  const storyTitle = (text: string | undefined) => {
    if (text === undefined) {
      return "";
    }

    const regex = /(\n)/g;

    return text.split(regex).map(function (line: string, index: number) {
      return <p className="title is-3 has-text-white">{line}</p>;
    });
  };

  // const averageStar = () => {
  //   if (storyContainer.reviewList.length === 0) {
  //     return 0;
  //   }
  //   const totalStar: number = storyContainer.reviewList
  //     .map((review) => {
  //       return review.star;
  //     })
  //     .reduce((prev, current) => {
  //       return prev + current;
  //     });
  //   const averageStar: number = totalStar / storyContainer.reviewList.length;
  //   return averageStar;
  // };

  return (
    <div className={styles.container}>
      <img className="background" src={storyContainer.titleImageUrl}></img>
      <div className="black-mask"></div>
      <div className="storyTitle">
        {storyTitle(storyContainer.story?.title)}
        {/* <StarComponent
          star={averageStar()}
          numberOfReviews={storyContainer.reviewList.length}
        /> */}
      </div>
      {storyContainer.story !== undefined && (
        <div className="story-top-footer has-text-white">
          <div className="text-caution">※動画作品の場合、音声が流れます※</div>
          <button className="trial button is-light" onClick={onClickTrial}>
            {storyContainer.story?.price == 0 ? "スタート" : "お試しスタート"}
          </button>

          <div className="purchase has-text-white" onClick={onClickPurchase}>
            {storyContainer.story?.price == 0
              ? "全編無料"
              : "全編プレイ" + storyContainer.story?.price + "円"}
          </div>
        </div>
      )}

      {/* {isModalShow && (
        <PurchaseComponent
          story={storyContainer.story!}
          onCloseHandler={() => {
            setIsModalShow(false);
          }}
          onPurchaseCompletedHandler={() => {
            setIsModalShow(false);
          }}
        />
      )} */}
    </div>
  );
};
