import React, { useState, useEffect, useRef, MouseEvent } from "react";
import styles from "./video-cell.module.scss";
import { CSUserVideoRepository } from "@6cuts/storage/users/video";
import { UserMediaEntity } from "@6cuts/@dto/write.user";
import { TimeCalculator } from "../../../tool/time-calculator";
import { VideoPreviewComponent } from "./video-preview";
import { TextAreaAlertComponent, YesNoAlertComponent } from "components/common/alert/alert";
import { FSWriteUserMediaRepository } from "@6cuts/public-repository/write.user.media";

export interface VideoLibraryCellComponentProps {
    media: UserMediaEntity
    isSelectEnabled: boolean
    selected: boolean
    isEditMode: boolean
    onUpdateMediaDataHandler: (media: UserMediaEntity) => void
    onDeleteMediaDataHandler: (media: UserMediaEntity) => void
    onSelectMediaHandler?: (media: UserMediaEntity) => void
}

export const VideoCellComponent: React.FC<VideoLibraryCellComponentProps> = (props: VideoLibraryCellComponentProps) => {

    /// サムネイル画像URL
    const [thumbnailImageUrl, setThumbnailImageUrl] = useState<string>("")

    /// 動画URL
    const [videoUrl, setVideoUrl] = useState<string>("")

    /// 動画プレビューを表示するかどうか
    const [isShowVideoModal, setIsShowVideoModal] = useState<boolean>(false)

    /// 動画コメント編集を表示するかどうか
    const [isShowEditCommentModal, setIsShowEditCommentModal] = useState<boolean>(false)

    /// 動画プレビューを表示するかどうか
    const [isShowColorTagList, setIsShowColorTagList] = useState<boolean>(false)

    /// 削除アラートの表示
    const [isShowDeleteAlertModal, setIsShowDeleteAlertModal] = useState<boolean>(false)
    const DELETE_ALERT_MESSAGE: string = `
    削除してよろしいですか？
    作業途中のストーリーに設定している場合は、該当チャプターへの動画の再設定が必要になります。
    `

    /// ビデオ時間の取得
    const videoDuration: string = TimeCalculator.toString({
        time: props.media.duration,
        hour: false,
        min: true,
        sec: true,
    })

    /// カラータグパターン
    const colorTagList: string[] = [
        "red",
        "blue",
        "green",
        "black",
        "yellow",
        "purple",
        "navy",
        "gray",
    ]

    /**
     * ステータスがcompletedになったらサムネイル取得
     */
    useEffect(() => {
        if (props.media.status == "completed") {
            CSUserVideoRepository.getThumbnailImage({
                userId: props.media.userId,
                mediaId: props.media.id,
            }).then((url: string) => {
                setThumbnailImageUrl(url)
            })
        }
    })

    /**
     * Cellをタップしたときのアクション
     * 動画URLを取得してモーダルを表示
     */
    const onClickCellHandler = () => {
        // if (videoUrl === "") {
        //     console.log("get video url")
        //     CSUserVideoRepository.getVideoUrl({
        //         userId: props.media.userId,
        //         mediaId: props.media.id,
        //     }).then((url: string) => {
        //         console.log(url)
        //         setVideoUrl(url)
        //     })
        // }

        setIsShowVideoModal(true)
    }

    const onClickCheckmarkHandler = (event: any) => {
        console.log("check")
        event.preventDefault()
        event.stopPropagation()
        if (props.onSelectMediaHandler) {
            props.onSelectMediaHandler(props.media)
        }
    }

    const onClickCommentHandler = () => {
        setIsShowEditCommentModal(true)
    }

    /**
     * カラータグをクリックしたときの処理
     * カラーを一つ進める
     */
    const onClickColorTagHandler = (colorTag: string) => {
        let color: string = colorTag
        // colorTagList.forEach((colorTag: string, index: number) => {
        //     if (props.media.color === colorTag) {
        //         if (index + 1 >= colorTagList.length) {
        //             color = colorTagList[0]
        //         } else {
        //             color = colorTagList[index + 1]
        //         }
        //     }
        // })
        setIsShowColorTagList(false)

        if (props.media.color === color) {
            // 同じなら更新しない
            return
        }

        const newMedia: UserMediaEntity = { ...props.media, color: color }
        FSWriteUserMediaRepository.updateColor({
            userId: props.media.userId,
            mediaId: props.media.id,
            color: color,
        })
        props.onUpdateMediaDataHandler(newMedia)
    }

    /**
     * 削除ボタン押下したときの処理
     * 削除確認アラートを表示する
     */
    const onClickDeleteButtonHandler = () => {
        setIsShowDeleteAlertModal(true)
    }

    // -----------------------------------------------------
    //
    //  コメント編集アラートのコールバック
    //
    // -----------------------------------------------------
    const editCommentModalOnClickYesHandler = (text: string) => {
        console.log(text)
        setIsShowEditCommentModal(false)

        const newMedia: UserMediaEntity = { ...props.media, memo: text }
        props.onUpdateMediaDataHandler(newMedia)
        FSWriteUserMediaRepository.updateMemo({
            userId: props.media.userId,
            mediaId: props.media.id,
            memo: text,
        })
    }

    const editCommentModalOnClickNoHandler = () => {
        setIsShowEditCommentModal(false)
    }

    // -----------------------------------------------------
    //
    //  削除アラートのコールバック
    //
    // -----------------------------------------------------
    const deleteConfirmModalOnClickYesHandler = () => {
        setIsShowDeleteAlertModal(false)

        props.onDeleteMediaDataHandler(props.media)
        FSWriteUserMediaRepository.delete({
            userId: props.media.userId,
            mediaId: props.media.id,
        })
    }

    const deleteConfirmModalOnClickNoHandler = () => {
        setIsShowDeleteAlertModal(false)
    }

    // -----------------------------------------------------
    //
    //  動画プレビューのコールバック
    //
    // -----------------------------------------------------
    /**
     * モーダルを閉じるボタンタップ時のアクション
     * モーダルを閉じる
     */
    const onClickCloseModalHandler = () => {
        setIsShowVideoModal(false)
    }

    return (
        <div className={styles.component}>
            <article className="media">
                {
                    props.media.status === "prepare" && (
                        <figure className="image">
                            <button className="loading button is-loading is-text"></button>
                            <div className="prepare-message">処理中</div>
                        </figure>
                    )
                }
                {
                    (props.media.status !== "prepare" && thumbnailImageUrl === "") && (
                        <figure className="image">
                            <button className="loading button is-loading is-text"></button>
                        </figure>
                    )
                }
                {
                    (props.media.status !== "prepare" && thumbnailImageUrl !== "") && (
                        <figure className="image" onClick={onClickCellHandler}>
                            <img className="test" src={thumbnailImageUrl} />
                            <div className="mask"></div>
                            {
                                props.isSelectEnabled && props.selected && (
                                    <i className="fas fa-check-circle selected" onClick={(e) => onClickCheckmarkHandler(e)}></i>
                                )
                            }
                            {
                                props.isSelectEnabled && !props.selected && (
                                    <i className="far fa-check-circle" onClick={(e) => onClickCheckmarkHandler(e)}></i>
                                )
                            }
                        </figure>
                    )
                }
                <div className="media-content" onClick={onClickCommentHandler}>
                    <div className="content">
                        {
                            props.media.memo === "" && (
                                <p className="has-text-grey-light">メモ(最大32文字)</p>
                            )
                        }
                        {
                            props.media.memo !== "" && (
                                <p>{props.media.memo}</p>
                            )
                        }
                    </div>
                </div>

                <div className={`dropdown is-right ${isShowColorTagList ? "is-active" : ""}`}>
                    <div className="dropdown-trigger">
                        <div className={`color-tag absolute`} onClick={() => setIsShowColorTagList(!isShowColorTagList)}>
                            <div className={`value ${props.media.color}`}></div>
                        </div>
                    </div>
                    <div className="dropdown-menu" role="menu">
                        <div className="dropdown-content">
                            <div className="dropdown-item">
                                <div className="color-tag-list">
                                    {
                                        colorTagList.map((colorTag: string, index: number) => {
                                            return (
                                                <div className="color-tag inline-block" key={index} onClick={() => onClickColorTagHandler(colorTag)}>
                                                    <div className={`value ${colorTag}`}></div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    props.isEditMode && (
                        <div className="delete-button" onClick={onClickDeleteButtonHandler}>
                            <i className="fas fa-minus"></i>
                        </div>
                    )
                }
            </article>
            <div className="duration">{videoDuration}</div>


            <TextAreaAlertComponent
                title="メモ編集"
                message="メモを入力してください"
                defaultText={props.media.memo}
                yesCallback={editCommentModalOnClickYesHandler}
                noCallback={editCommentModalOnClickNoHandler}
                isOpen={isShowEditCommentModal}
            />
            <YesNoAlertComponent
                title="確認"
                message={DELETE_ALERT_MESSAGE}
                yesCallback={deleteConfirmModalOnClickYesHandler}
                noCallback={deleteConfirmModalOnClickNoHandler}
                isOpen={isShowDeleteAlertModal}
            />
            {
                isShowVideoModal && (
                    <VideoPreviewComponent
                        userId={props.media.userId}
                        mediaId={props.media.id}
                        memo={""}
                        onCloseHandler={onClickCloseModalHandler}
                    />
                )
            }
        </div>
    )
}

