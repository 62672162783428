import React, { useState, useEffect } from "react";
import { ReadStoryEntity } from "@6cuts/@dto/read.story";

import {
  YesNoAlertComponent,
  OkAlertComponent,
} from "../../../common/alert/alert";

import styles from "./released-menu.module.scss";
import { FSWriteEventRepository } from "@6cuts/public-repository/write.event";
import { useHistory } from "react-router-dom";
import { FSWriteStoryRepository } from "@6cuts/public-repository/write.story";

interface StoryReleasedMenuProps {
  story: ReadStoryEntity | null;
}

export const StoryReleasedMenuComponent: React.FC<StoryReleasedMenuProps> = (
  props
) => {
  const history = useHistory();
  const [isCopyAlert, setIsCopyAlert] = useState<boolean>(false);
  const [isDeleteAlert, setIsDeleteAlert] = useState<boolean>(false);
  const [isCloneAlert, setIsCloneAlert] = useState<boolean>(false);

  const onClickCloneButtonHandler = () => {
    FSWriteEventRepository.createStoryCloneEvent({
      storyId: props.story!.id,
      userId: props.story!.ownerId,
    });
    setIsCloneAlert(false);
    history.goBack();
  };

  const onClickDeleteButtonHandler = () => {
    FSWriteStoryRepository.delete({
      storyId: props.story!.id,
    });
    setIsDeleteAlert(false);
    history.goBack();
  };

  return (
    <div className={styles.component}>
      <div className="copy-delete">
        <button
          className="button has-text-white"
          onClick={() => {
            setIsCloneAlert(true);
          }}
        >
          非公開で複製
        </button>

        <button
          className="button has-text-white"
          onClick={() => {
            setIsDeleteAlert(true);
          }}
        >
          削除する
        </button>
      </div>
      <div className="detail">
        <p className="has-text-white">価格:{props.story?.price}円/プレイ</p>
        <p className="has-text-white">
          課金ポイント:チャプター{props.story?.billingChapterId}
        </p>
        <p className="has-text-white">
          スコアの利用:{props.story?.scoreEnable ? "あり" : "なし"}
        </p>
        <p className="has-text-white">スコア名:{props.story?.scoreName}</p>
        <p className="has-text-white">初期スコア:{props.story?.initScore}</p>

        <div className="columns is-gapless is-mobile">
          <div className="column">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/copy.png`}
              onClick={() => {
                window.open(`https://6cuts.com/story/${props.story?.id}`);
              }}
              alt=""
            />
            <div className="has-text-white">開く</div>
          </div>
          <div className="column">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/open.png`}
              onClick={() => {
                navigator.clipboard.writeText(
                  `https://6cuts.com/story/${props.story?.id}`
                );
                setIsCopyAlert(true);
              }}
              alt=""
            />
            <div className="has-text-white">URLをコピー</div>
          </div>
        </div>
      </div>
      <OkAlertComponent
        title="完了"
        message="URLをコピーしました"
        okCallback={() => {
          setIsCopyAlert(false);
        }}
        isOpen={isCopyAlert}
      />
      <YesNoAlertComponent
        title="確認"
        message="削除しますか？"
        yesCallback={onClickDeleteButtonHandler}
        noCallback={() => {
          setIsDeleteAlert(false);
        }}
        isOpen={isDeleteAlert}
      />
      <YesNoAlertComponent
        title="確認"
        message="複製しますか？"
        yesCallback={onClickCloneButtonHandler}
        noCallback={() => {
          setIsCloneAlert(false);
        }}
        isOpen={isCloneAlert}
      />
    </div>
  );
};
