import React, { useState, useEffect, useRef } from "react";
import styles from "./page.module.scss";

import * as firebase from "firebase";

import { AppContainer } from "../../containers/app/app.container";
import { useHistory } from "react-router";

/*
 * サインイン
 */
export const TopPageComponent: React.FC = () => {
    const appContainer = AppContainer.useContainer();
    const history = useHistory();

    const moveTo = (path: string) => {
        history.push(path)
    }

    const onClickSignOutHandler = () => {
        firebase.auth().signOut()
        window.location.href = "/sign-in"
    }

    return (
        <div className={styles.page}>
            <nav className="navbar is-primary" role="navigation" aria-label="main navigation">
                <div className="navbar-brand">
                    <a className="navbar-item">
                        <figure className="image is-24x24 is-1by1">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/6cuts_top_icon.png`} />
                        </figure>
                    </a>
                </div>
            </nav>

            <section className="section">
                <div className="container">

                    <h2 className="subtitle has-text-weight-bold">{appContainer.user?.nickname}</h2>

                    <aside className="menu">
                        <div className="menu-separator"></div>
                        <ul className="menu-list">
                            <li><a onClick={() => moveTo("/story")}>ストーリー</a></li>
                            <li><a onClick={() => moveTo("/video/library")}>動画ライブラリ</a></li>
                        </ul>
                        <div className="menu-separator"></div>
                        <ul className="menu-list">
                            <li><a onClick={() => moveTo("/account/edit")}>アカウント設定</a></li>
                            <li><a>ダッシュボード</a></li>
                            <li><a>その他</a></li>
                        </ul>
                        <div className="menu-separator"></div>
                        <ul className="menu-list">
                            <li><a onClick={onClickSignOutHandler}>ログアウト</a></li>
                        </ul>
                    </aside>
                </div>
            </section>
        </div>
    );
};
