import * as firebase from "firebase"



export class CSUserImageRepository {

    static directoryPath = (userId: string) => {
        return `users/${userId}`
    }

    /*
        ユーザの画像URLを取得する 
     */
    static getUserImageUrl(
        data: {
            userId: string,
        }
    ): Promise<string> {
        const ref = firebase.storage().ref(`${this.directoryPath(data.userId)}/${data.userId}`)
        return ref
            .getDownloadURL()
            .then((value: any) => {
                const url: string = value as string
                return url
            })
            .catch((error) => {
                console.log(error)
                throw error
            })
    }

    /*
        サムネイル画像を取得する
        サムネイル画像がなければ大きいほうの画像を返す？
     */
    static getUserThumbnailImageUrl(
        data: {
            userId: string,
        }
    ): Promise<string> {
        const ref = firebase.storage().ref(`${this.directoryPath(data.userId)}/${data.userId}_thumbnail`)
        return ref
            .getDownloadURL()
            .then((value: any) => {
                const url: string = value as string
                return url
            })
            .catch((error) => {
                console.log(error)
                return this.getUserImageUrl({ userId: data.userId })
            })
    }

    /* 
        ユーザの画像をアップロードする
     */
    static upload(
        data: {
            userId: string,
            file: File
        }
    ): Promise<boolean> {
        const ref = firebase.storage().ref(`${this.directoryPath(data.userId)}/${data.userId}`)
        return ref
            .put(data.file)
            .then((_) => {
                return true
            })
            .catch((error) => {
                console.log(error)
            })
    }
}