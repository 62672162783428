import { getMaxChapterNumber } from "../../chapter-list/chapter-list-page";
import styles from "./select-action.module.scss";
import React, { useState } from "react";
import { ChapterActionSelect } from "@6cuts/@dto/write.story";
import { ChapterContainer } from "containers/story/chapter.container";

interface ChapterSelectProps {
  selectNum: number;
  action: ChapterActionSelect;
  scoreEnable: boolean;
  capterId: string;
}

export const ChapterSelectComponent: React.FC<ChapterSelectProps> = (props) => {
  let transferIdtList = [];
  const chapterContainer = ChapterContainer.useContainer();
  const [pointValid, setPointValid] = useState<boolean>(true);
  const [selectPointLimitValid, setSelectPointLimitValid] = useState<boolean>(
    true
  );

  // for (var i = Number(props.capterId); i < getMaxChapterNumber(); i++) {
  //   transferIdtList.push(<option value={(i + 1).toString()}>{i + 1}</option>);
  // }
  for (var i = 0; i < getMaxChapterNumber(); i++) {
    transferIdtList.push(<option value={(i + 1).toString()}>{i + 1}</option>);
  }

  // 説明の変更された時の処理
  const onChangeTextHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length <= 20) {
      props.action.text = event.target.value;
    }
  };

  //ポイント制限変更された時の処理
  const onChangeSelectPointLimitHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = Number(event.target.value);
    setSelectPointLimitValid(chapterContainer.checkPoint(value));
    props.action.selectPointLimit = value;
  };

  //ポイント変更された時の処理
  const onChangePointHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    setPointValid(chapterContainer.checkPoint(value));
    props.action.point = Number(event.target.value);
  };

  //遷移先変更された時の処理
  const onChangeTransitionIdHandler = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    props.action.transitionId = event.target.value;
  };

  return (
    <div className={styles.select}>
      <div className="card">
        <header className="card-header">
          <p className="card-header-title title is-5 has-text-white">
            選択肢
            {props.selectNum}
          </p>
        </header>
        <div className="card-content">
          <div className="title is-6">
            選択肢
            {props.selectNum}
            のテキスト
          </div>
          <div className="input-text">
            <input
              className="input"
              type="text"
              defaultValue={props.action.text}
              onChange={onChangeTextHandler}
              maxLength={20}
            />
            <label>※最大20文字</label>
          </div>
          {props.scoreEnable && (
            <div className="score">
              <div className="title is-6">
                選択肢
                {props.selectNum}
                のスコア条件
              </div>
              <div className="input-text">
                <input
                  className={[
                    "input",
                    selectPointLimitValid ? "" : "is-danger",
                  ].join(" ")}
                  type="number"
                  onChange={onChangeSelectPointLimitHandler}
                  defaultValue={props.action.selectPointLimit.toString()}
                />
                <label>※-10000~10000で設定</label>
              </div>
              <div className="title is-6">
                選択肢
                {props.selectNum}
                の獲得スコア
              </div>
              <div className="input-text">
                <input
                  className={["input", pointValid ? "" : "is-danger"].join(" ")}
                  type="number"
                  onChange={onChangePointHandler}
                  defaultValue={props.action.point.toString()}
                />
                <label>※-10000~10000で設定</label>
              </div>
            </div>
          )}
          <div className="title is-6">
            選択肢
            {props.selectNum}
            の遷移先ID
          </div>
          <div className="id-select">
            <div className="select is-primary">
              <select
                onChange={(e) => onChangeTransitionIdHandler(e)}
                defaultValue={props.action.transitionId.toString()}
              >
                {props.action.transitionId === "" && (
                  <option value="">選択してください</option>
                )}
                {transferIdtList}
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
