import React, { useState, useRef, useEffect } from "react";
import { Route, Redirect, RouteProps, useLocation } from "react-router";

import * as firebase from "firebase";

import { AppContainer } from "./containers/app/app.container";

/* ログイン状態専用のルーティング */
export const PrivateRoute: React.FC<RouteProps> = (props) => {
    const appContainer = AppContainer.useContainer();
    const location = useLocation();
    const isAuth: boolean = firebase.auth().currentUser !== null;

    console.log("private route")
    console.log(props)
    if (isAuth === false || appContainer.user === null) {
        return <Redirect to="/sign-in" />;
    } else if (
        appContainer.user.nickname === "" &&
        location.pathname !== "/account/edit"
    ) {
        return <Redirect to="/account/edit" />;
    } else {
        return <Route {...props} />;
    }
};

/* 未ログイン状態専用のルーティング */
export const GuestRoute: React.FC<RouteProps> = (props) => {
    const isAuth: boolean = firebase.auth().currentUser !== null;
    console.log("guest route")
    console.log(props)
    return !isAuth ? <Route {...props} /> : <Redirect to="/top" />;
};
