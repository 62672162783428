import * as firebase from "firebase";

const config = () => {
  if (process.env.REACT_APP_FB_ENV === "prod") {
    // 本番環境
    return {
      publicStorage: process.env.REACT_APP_PRODUCTION_PUBLIC_STORAGE_NAME
    }
  } else {
    // 開発検証環境
    return {
      publicStorage: process.env.REACT_APP_DEV_PUBLIC_STORAGE_NAME
    }
  }
}

export const storageEnvironment = config()
