import { useState, useEffect, useRef } from "react";
import { createContainer } from "unstated-next";
import firebase from "firebase";
import { useParams } from "react-router-dom";
import {
  ChapterActionType,
  ChapterActionSelect,
  ChapterActionKeyword,
  WriteStoryChapterEntity,
} from "@6cuts/@dto/write.story";
import { FSWriteStoryChapterRepository } from "@6cuts/public-repository/write.story.chapter";
import { ReadChapter, ReadSentence } from "@6cuts/@dto/read.story";

export const MAX_POINT = 10000;
export const MIN_POINT = -10000;

const useChapterContainer = (initialState: ReadChapter | null = null) => {
  const { chapterId } = useParams();
  const [chapter, setChapter] = useState<ReadChapter | null>(
    initialState ? initialState : null
  );
  const [actionValue, setActionValue] = useState<string>("");
  // const [chapterId, setChapterId] = useState<string>(
  //   initialState ? initialState.chapterId : ""
  // );
  const [actionType, setActionType] = useState<ChapterActionType>(
    ChapterActionType.SELECT
  );
  const [explanation, setExplanation] = useState<string>("");
  const [selectData, setSelectData] = useState<ChapterActionSelect[]>([]);
  const [keywordAnswerData, setKeywordAnswerData] = useState<
    ChapterActionKeyword[]
  >([]);
  const [keywordIncorrectData, setKeywordIncorrectData] = useState<
    ChapterActionKeyword
  >(FSWriteStoryChapterRepository.getActionKeywordChapter());
  const [actionComponentList, setActionComponentList] = useState<JSX.Element[]>(
    []
  );

  useEffect(() => {
    if (initialState !== null) {
      setChapter(initialState);
      setExplanation(initialState.explanation);
      setActionType(initialState.actionType);
      setSelectData(initialState.select);
      // setChapterId(initialState.chapterId);
      setKeywordAnswerData(initialState.keyword.answer);
      setKeywordIncorrectData(initialState.keyword.incorrect);

      let actionValue = "";
      switch (initialState.actionType) {
        case ChapterActionType.SELECT:
          actionValue =
            initialState.actionType + initialState.select.length.toString();
          break;
        case ChapterActionType.KEYWORD:
          actionValue =
            initialState.actionType +
            initialState.keyword.answer.length.toString();
          break;
        case ChapterActionType.END:
          actionValue = initialState.actionType;
          break;
        case ChapterActionType.NONE:
          actionValue = "";
          break;
      }
      setActionValue(actionValue);
    }
  }, [initialState]);

  const getChapter = () => {
    const now = firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp;
    let sentenceIdList: string[] = [];
    if (chapter !== null) {
      chapter.sentenceList.forEach((sentence: ReadSentence) => {
        sentenceIdList.push(sentence.id);
      });
    }
    const data: WriteStoryChapterEntity = {
      id: chapter !== null ? chapter.id : "",
      sentenceIdList: sentenceIdList,
      chapterId: chapterId,
      actionType: actionType,
      explanation: explanation,
      select: selectData,
      keyword: {
        answer: keywordAnswerData,
        incorrect: keywordIncorrectData,
      },
      created: now,
      updated: now,
    };
    console.log(data);
    return data;
  };

  const checkChapter = (chapter: WriteStoryChapterEntity) => {
    let isValid = true;
    chapter.select.forEach((select) => {
      if (!checkPoint(select.point)) {
        isValid = false;
      }
      if (!checkPoint(select.selectPointLimit)) {
        isValid = false;
      }
    });
    chapter.keyword.answer.forEach((keyword) => {
      if (!checkPoint(keyword.point)) {
        isValid = false;
      }
      if (!checkZenKatakana(keyword.text)) {
        isValid = false;
      }
    });
    if (!checkPoint(chapter.keyword.incorrect.point)) {
      isValid = false;
    }

    return isValid;
  };

  const checkPoint = (point: number) => {
    return point >= MIN_POINT && point <= MAX_POINT;
  };

  const checkZenKatakana = (str: string) => {
    str = str == null ? "" : str;
    if (str.match(/^[ァ-ヶー　]+$/)) {
      return true;
    } else {
      return false;
    }
  };

  const resetActionData = () => {
    setSelectData([]);
    setKeywordAnswerData([]);
    setKeywordIncorrectData({
      text: "",
      point: 0,
      transitionId: "",
    });
  };

  return {
    chapter,
    actionValue,
    actionType,
    explanation,
    selectData,
    keywordAnswerData,
    keywordIncorrectData,
    actionComponentList,
    setChapter,
    setActionValue,
    setActionType,
    setExplanation,
    setSelectData,
    setKeywordAnswerData,
    setKeywordIncorrectData,
    setActionComponentList,
    getChapter,
    resetActionData,
    checkChapter,
    checkPoint,
    checkZenKatakana,
  };
};
export const ChapterContainer = createContainer(useChapterContainer);
