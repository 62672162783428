import { getMaxChapterNumber } from "../../chapter-list/chapter-list-page";
import styles from "./keyword-action.module.scss";

import React, { useState } from "react";
import { ChapterActionKeyword } from "@6cuts/@dto/write.story";
import { ChapterContainer } from "containers/story/chapter.container";

interface ChapterKeywordProps {
  keywordNum?: number;
  action: ChapterActionKeyword;
  isAnswer: boolean;
  scoreEnable: boolean;
  capterId: string;
}

export const ChapterKeywordComponent: React.FC<ChapterKeywordProps> = (
  props
) => {
  let transferIdtList = [];
  const chapterContainer = ChapterContainer.useContainer();
  const [pointValid, setPointValid] = useState<boolean>(true);
  const [keywordValid, setKeywordValid] = useState<boolean>(true);

  // for (var i = Number(props.capterId); i < getMaxChapterNumber(); i++) {
  //   transferIdtList.push(<option value={(i + 1).toString()}>{i + 1}</option>);
  // }
  for (var i = 0; i < getMaxChapterNumber(); i++) {
    transferIdtList.push(<option value={(i + 1).toString()}>{i + 1}</option>);
  }

  // 説明の変更された時の処理
  const onChangeTextHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length <= 20) {
      props.action.text = event.target.value;
    }
    setKeywordValid(chapterContainer.checkZenKatakana(event.target.value));
  };

  //ポイント変更された時の処理
  const onChangePointHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    setPointValid(chapterContainer.checkPoint(value));
    props.action.point = value;
  };

  //遷移先変更された時の処理
  const onChangeTransitionIdHandler = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    props.action.transitionId = event.target.value;
  };

  return (
    <div className={styles.keyword}>
      <div className="card">
        <header className="card-header">
          <p className="card-header-title title is-5 has-text-white">
            {props.isAnswer
              ? `キーワード${props.keywordNum}`
              : "キーワード以外"}
          </p>
        </header>
        <div className="card-content">
          {props.isAnswer && <div className="title is-6">キーワード</div>}
          {props.isAnswer && (
            <div className="input-text">
              <input
                className={["input", keywordValid ? "" : "is-danger"].join(" ")}
                type="text"
                defaultValue={props.action.text}
                onChange={onChangeTextHandler}
                placeholder="カタカナで入力"
                maxLength={20}
              />
              <label>※最大20文字（全角カタカナ）</label>
            </div>
          )}
          {props.scoreEnable && (
            <div className="score">
              <div className="title is-6">
                {props.isAnswer
                  ? `指定キーワード${props.keywordNum}`
                  : "キーワード以外"}
                の獲得スコア
              </div>
              <div className="input-text">
                <input
                  className={["input", pointValid ? "" : "is-danger"].join(" ")}
                  type="number"
                  onChange={onChangePointHandler}
                  defaultValue={props.action.point.toString()}
                />
                <label>※-10000~10000で設定</label>
              </div>
            </div>
          )}
          <div className="title is-6">
            {props.isAnswer
              ? `指定キーワード${props.keywordNum}`
              : "キーワード以外"}
            の遷移先ID
          </div>
          <div className="id-select">
            <div className="select is-primary">
              <select
                onChange={(e) => onChangeTransitionIdHandler(e)}
                defaultValue={props.action.transitionId.toString()}
              >
                {props.action.transitionId === "" && (
                  <option value="">選択してください</option>
                )}
                {transferIdtList}
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
