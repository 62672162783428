import React, { useState, useEffect, useRef } from "react";
import styles from "./video-preview.module.scss";
import { CSUserVideoRepository } from "@6cuts/storage/users/video";

export interface VideoPreviewComponentProps {
    userId: string
    mediaId: string
    memo: string
    onCloseHandler: () => void
}

export const VideoPreviewComponent: React.FC<VideoPreviewComponentProps> = (props: VideoPreviewComponentProps) => {

    /// 動画DOM
    const videoEl = useRef<HTMLVideoElement>(null);

    // VideoUrl
    const [videoUrl, setVideoUrl] = useState<string>("")

    /// リプレイボタンを表示するかどうか
    const [isShowReplay, setIsShowReplay] = useState<boolean>(false)

    useEffect(() => {
        CSUserVideoRepository.getVideoUrl({
            userId: props.userId,
            mediaId: props.mediaId,
        }).then((url: string) => {
            console.log(url)
            setVideoUrl(url)
        })
    }, [props])

    /**
     * モーダルを閉じるボタンタップ時のアクション
     * モーダルを閉じる
     */
    const onClickCloseModalHandler = () => {
        setIsShowReplay(false)
        props.onCloseHandler()
    }

    /**
     * 動画再生終了時のコールバック
     * リプレイボタンを表示する
     */
    const onEndVideoHandler = () => {
        setIsShowReplay(true)
    }

    /**
     * リプレイボタンタップ時のアクション
     * 動画を最初から再生してリプレイボタンを非表示にする
     */
    const onClickReplayHandler = () => {
        if (videoEl.current !== null) {
            videoEl.current.currentTime = 0
            videoEl.current.play()
        }
        setIsShowReplay(false)
    }

    return (
        <div className={styles.component}>
            <div className="modal is-active">
                <div className="modal-background"></div>
                <div className="modal-content">
                    <section className="hero is-fullheight">
                        <video
                            ref={videoEl}
                            className="background-video"
                            muted={false}
                            autoPlay
                            playsInline={true}
                            src={`${videoUrl}#t=0.01`}
                            onEnded={onEndVideoHandler}
                        ></video>
                        <div className="black-mask"></div>
                        {
                            (isShowReplay) && (
                                <div className="replay">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/reload.png`} onClick={onClickReplayHandler} />
                                </div>
                            )
                        }
                    </section>
                </div>
                <button className="modal-close is-large" aria-label="close" onClick={onClickCloseModalHandler}></button>
            </div>
        </div>
    )
}

