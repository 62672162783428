import React, { useState, useRef, useEffect } from "react";
import styles from "./top-page.module.scss";
import { useParams } from "react-router-dom";
import { TextAreaAlertComponent } from "../../../common/alert/alert";
import { NaviComponent } from "../../../common/navi/navi";
import { FSReadStoryRepository } from "@6cuts/public-repository/read.story";
import { ReadStoryEntity } from "@6cuts/@dto/read.story";
import { CSStoryImageRepository } from "@6cuts/storage/story/image";
import { FSWriteStoryRepository } from "@6cuts/public-repository/write.story";
import { StoryEditMenuComponent } from "./edit-menu";
import { StoryReleasedMenuComponent } from "./released-menu";

export const StoryEditTopPageComponent: React.FC = () => {
  const { storyId } = useParams();

  const [story, setStory] = useState<ReadStoryEntity | null>(null);
  const [title, setTitle] = useState<string>("");
  const [thumbnailFile, setThumbnailFile] = useState<File | null>(null);
  const [thumbnailFilePreviewData, setThumbnailFilePreviewData] = useState<
    string | undefined
  >(undefined);
  const [isTitleEditing, setIsTitleEditing] = useState<boolean>(false);
  const [published, setPublished] = useState<boolean>(false);

  const inputFileElement = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (storyId === "") {
      return;
    }
    console.log("watch : " + storyId);
    const subscription = FSReadStoryRepository.watchStory({
      storyId: storyId,
    }).subscribe((storyData: ReadStoryEntity | null) => {
      console.log("story data");
      console.log(storyData);
      if (storyData !== null) {
        setStory(storyData);
        setTitle(storyData.title);
        setPublished(storyData.published);
        if (storyData.thumbnailImage !== "") {
          CSStoryImageRepository.getStoryThumbnailImageUrl({
            storyId: storyData.id,
          }).then((imageUrl: string) => {
            setThumbnailFilePreviewData(imageUrl);
          });
        }
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [storyId]);

  //サムネイルの変更を監視
  useEffect(() => {
    onUploadHandler();
  }, [thumbnailFile]);

  /**
   * タイトル改行表示用
   * @param text 
   */
  const storyTitle = (text: string | undefined) => {
    if (text === undefined) {
      return "";
    }

    const regex = /(\n)/g;

    return text.split(regex).map(function (line: string, index: number) {
      return <p className="title is-3 has-text-white">{line}</p>;
    });
  };

  //タイトル変更ボタンハンドラー
  const onClickTitleHandler = () => {
    setIsTitleEditing(true);
  };

  //TOP画像編集ボタンハンドラー
  const onClickImageButtonHandler = () => {
    inputFileElement?.current?.click();
  };

  /**
   * タイトル編集のOKボタンハンドラー
   * @param text
   */
  const onClickTextEditingYes = (text: string) => {
    if (text !== "") {
      setTitle(text);
      if (story) {
        FSWriteStoryRepository.updateTitle({
          storyId: storyId,
          title: text,
        });
      }
      setIsTitleEditing(false);
    }
  };

  /**
   * タイトル編集のキャンセルボタンハンドラー
   * @param text
   */
  const onClickTextEditingNo = () => {
    setIsTitleEditing(false);
  };

  /**
   * サムネイル変更ハンドラー
   * @param event
   */
  const onChangeThumbnailFileHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files !== null && event.target.files.length > 0) {
      const file: File = event.target.files[0];
      setThumbnailFile(file);

      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target?.result === undefined) {
          setThumbnailFilePreviewData(undefined);
        } else {
          setThumbnailFilePreviewData(e.target?.result as string);
        }
      };
      reader.readAsDataURL(file);
    } else {
      setThumbnailFile(null);
      setThumbnailFilePreviewData(undefined);
    }
  };

  /**
   * 画像アップロード
   */
  const onUploadHandler = async () => {
    console.log(thumbnailFile);
    if (story !== null) {
      if (thumbnailFile !== null) {
        const result: boolean = await CSStoryImageRepository.upload({
          storyId: storyId,
          file: thumbnailFile,
        });
        console.log(result);
        if (result === false) {
          return;
        }
        FSWriteStoryRepository.updateThumbnailImage({
          storyId: storyId,
          thumbnailImage: storyId,
        });
      }
    }
  };

  return (
    <div className={styles.page}>
      <NaviComponent />
      <img className="background" src={thumbnailFilePreviewData} alt=""></img>
      {thumbnailFilePreviewData !== null && <div className="mask"></div>}
      {!published && (
        <button
          className="top-image button has-text-white"
          onClick={onClickImageButtonHandler}
        >
          TOP画像設定
        </button>
      )}
      {story !== null && (
        <div className="title">
          <label className="has-text-white is-size-3">
            {storyTitle(title !== "" ? title : "タイトル")}
          </label>
          {!published && (
            <span className="title-edit" onClick={onClickTitleHandler}>
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/edit.svg`}
                alt=""
              />
            </span>
          )}
        </div>
      )}
      {story !== null && !published && <StoryEditMenuComponent story={story} />}
      {story !== null && published && (
        <StoryReleasedMenuComponent story={story} />
      )}
      <TextAreaAlertComponent
        title="タイトル編集"
        message="タイトルを入力してください"
        defaultText={story?.title}
        yesCallback={onClickTextEditingYes}
        noCallback={onClickTextEditingNo}
        isOpen={isTitleEditing}
      />
      <input
        ref={inputFileElement}
        className="is-hidden"
        type="file"
        accept="image/*"
        onChange={onChangeThumbnailFileHandler}
      />
    </div>
  );
};
