import React, { useState, useRef, useEffect } from "react";
import { BrowserRouter, Route, Link, Switch } from "react-router-dom";

import * as firebase from "firebase";
import { authState } from "rxfire/auth";
import { take } from "rxjs/operators";

import "environment/firebase_env";

import { GuestRoute, PrivateRoute } from "./route";
import { AppContainer } from "./containers/app/app.container";
import { GeneralContainer } from "./containers/general.container";

import { AuthService } from "@6cuts/auth/auth-service";

import { LoadingPageComponent } from "./components/loading/page";
import { SignInTopPageComponent } from "./components/sign-in/top/page";
import { SignInEmailPageComponent } from "./components/sign-in/email-sign-in/page";
import { SignUpEmailPageComponent } from "./components/sign-in/email-sign-up/page";

import { AccountEditPageComponent } from "./components/account/edit/page";
import { StoryManagementPageComponent } from "components/story/management/management-page";
import { StoryEditTopPageComponent } from "components/story/edit/top/top-page";
import { StoryRulePageComponent } from "components/story/edit/rule/rule-page";
import { StoryChapterListPageComponent } from "components/story/edit/chapter-list/chapter-list-page";
import { StoryChapterPageComponent } from "components/story/edit/chapter/chapter-page";
import { TopPageComponent } from "./components/top/page";
import { StoryComponent } from "./components/story/preview/story.component";
import { VideoLibraryBrowsePageComponent } from "components/video/library/browse-page";

export const App: React.FC = () => {
  return (
    <GeneralContainer>
      <AppComponent />
    </GeneralContainer>
  );
};

const AppComponent: React.FC = () => {
  const appContainer = AppContainer.useContainer();
  const [isAuth, setIsAuth] = useState<boolean | null>(null);

  // ユーザ認証情報を取得する
  useEffect(() => {
    // 検証用サインアウト処理
    // AuthService.signOut()

    authState(firebase.auth())
      .pipe(take(1))
      .subscribe((user: firebase.User | null) => {
        console.log(user);
        if (user !== null) {
          appContainer.setUserId(user.uid);
        }
        setIsAuth(user !== null);
      });
  }, []);

  // 認証状況取得中 または ユーザ情報取得中 Loadingを表示
  if (isAuth === null || (isAuth === true && appContainer.user === null)) {
    return <LoadingPageComponent />;
  }

  return (
    <BrowserRouter>
      <Switch>
        {/* 未ログイン状態でのみ表示されるもの */}
        <GuestRoute exact path="/sign-in" component={SignInTopPageComponent} />
        <GuestRoute
          exact
          path="/sign-in/email"
          component={SignInEmailPageComponent}
        />
        <GuestRoute
          exact
          path="/sign-up/email"
          component={SignUpEmailPageComponent}
        />
        {/* ログイン状態でのみ表示されるもの */}
        <PrivateRoute
          exact
          path="/account/edit"
          component={AccountEditPageComponent}
        />
        <PrivateRoute
          exact
          path="/story"
          component={StoryManagementPageComponent}
        />
        <PrivateRoute
          exact
          path="/story/edit/:storyId"
          component={StoryEditTopPageComponent}
        />
        <PrivateRoute
          exact
          path="/story/edit/rule/:storyId"
          component={StoryRulePageComponent}
        />
        <PrivateRoute
          exact
          path="/story/edit/chapter-list/:storyId"
          component={StoryChapterListPageComponent}
        />
        <PrivateRoute
          exact
          path="/story/edit/chapter-list/chapter/:storyId/:chapterId"
          component={StoryChapterPageComponent}
        />
        <PrivateRoute exact path="/top" component={TopPageComponent} />
        <PrivateRoute
          exact
          path="/video/library"
          component={VideoLibraryBrowsePageComponent}
        />
        <PrivateRoute
          exact
          path="/story/edit/preview/:storyId"
          component={StoryComponent}
        />
      </Switch>
    </BrowserRouter>
  );
};
