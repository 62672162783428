import React, { useState, useEffect, useRef } from "react";
import styles from "./single-select-page.module.scss";

import { AppContainer } from "../../../containers/app/app.container";
import { useHistory } from "react-router";
import { VideoLibraryPageComponent, VideoLibraryStyle } from "./page";
import { UserMediaEntity } from "@6cuts/@dto/write.user";

interface VideoLibrarySingleSelectPageComponent {
    onTapBackButtonHandler: () => void
    onSelectMediaHandler: (media: UserMediaEntity) => void
}

/*
 * サインイン
 */
export const VideoLibrarySingleSelectPageComponent: React.FC<VideoLibrarySingleSelectPageComponent> = (props: VideoLibrarySingleSelectPageComponent) => {
    const appContainer = AppContainer.useContainer();
    const history = useHistory();

    // 選択中のメディア一覧
    const [selectedMedia, setSelectedMedia] = useState<UserMediaEntity | null>(null)

    const onClickBackButtonHandler = () => {
        props.onTapBackButtonHandler()
    }

    const onClickDoneHandler = () => {
        if (selectedMedia === null) { return }
        props.onSelectMediaHandler(selectedMedia)
    }

    const onSelectMediaHandler = (mediaList: UserMediaEntity[]) => {
        console.log("media list")
        console.log(mediaList)
        if (mediaList.length > 0) {
            setSelectedMedia(mediaList[0])
        } else {
            setSelectedMedia(null)
        }
    }

    return (
        <div className={styles.single_select_page}>
            <nav className="navbar is-primary" role="navigation" aria-label="main navigation">
                <div className="navbar-brand">
                    <a className="navbar-item" onClick={onClickBackButtonHandler}>
                        <i className="fas fa-chevron-left"></i>
                    </a>
                    <span className="navbar-item has-text-white">動画ライブラリ</span>
                    <button
                        className="navbar-burger button is-white is-small is-outlined"
                        disabled={selectedMedia === null}
                        onClick={onClickDoneHandler}
                    >決定</button>
                </div>
            </nav>

            <section className="section">
                <div className="container">
                    <VideoLibraryPageComponent
                        style={VideoLibraryStyle.singleSelect}
                        isEditMode={false}
                        onSelectMediaListHandler={onSelectMediaHandler}
                    />
                </div>
            </section>
        </div>
    );
};
