import * as firebase from "firebase";
import { WriteStoryEntity } from "@6cuts/@dto/write.story";

export class FSWriteStoryRepository {
  static readonly COLLECTION_NAME: string = "write.story";

  static getBlankStory(userId: string): WriteStoryEntity {
    const now = firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp;
    const story: WriteStoryEntity = {
      id: "",
      ownerId: userId,
      title: "",
      price: 0,
      scoreName: "",
      scoreUnit: "",
      scoreEnable: false,
      scoreVisible: true,
      initScore: 0,
      billingChapterId: "",
      numberOfFreePlays: 0,
      thumbnailImage: "",
      published: false,
      delete: false,
      titleVisible: false,
      created: now,
      updated: now,
    };
    return story;
  }

  static createStory(story: WriteStoryEntity): Promise<string> {
    const now = firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp;

    return firebase
      .firestore()
      .collection(this.COLLECTION_NAME)
      .add({
        ownerId: story.ownerId,
        title: story.title,
        price: story.price,
        scoreEnable: story.scoreEnable,
        scoreVisible: story.scoreVisible,
        scoreName: story.scoreName,
        scoreUnit: story.scoreUnit,
        initScore: story.initScore,
        thumbnailImage: story.thumbnailImage,
        billingChapterId: story.billingChapterId,
        numberOfFreePlays: story.numberOfFreePlays,
        titleVisible: story.titleVisible,
        published: false,
        delete: false,
        created: now,
        updated: now,
      })
      .then(
        (
          ref: firebase.firestore.DocumentReference<
            firebase.firestore.DocumentData
          >
        ) => {
          return ref.id;
        }
      );
  }

  static updateStory(story: WriteStoryEntity): Promise<void> {
    const now = firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp;
    return firebase
      .firestore()
      .collection(this.COLLECTION_NAME)
      .doc(story.id)
      .update({
        id: story.id,
        ownerId: story.ownerId,
        title: story.title,
        price: story.price,
        scoreEnable: story.scoreEnable,
        scoreVisible: story.scoreVisible,
        scoreName: story.scoreName,
        scoreUnit: story.scoreUnit,
        initScore: story.initScore,
        thumbnailImage: story.thumbnailImage,
        billingChapterId: story.billingChapterId,
        numberOfFreePlays: story.numberOfFreePlays,
        titleVisible: story.titleVisible,
        published: false,
        delete: story.delete,
        created: story.created,
        updated: now,
      });
  }

  static publish(data: { storyId: string }): Promise<void> {
    const now = firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp;
    return firebase
      .firestore()
      .collection(this.COLLECTION_NAME)
      .doc(data.storyId)
      .update({
        published: true,
        updated: now,
      });
  }

  static updateThumbnailImage(data: {
    storyId: string;
    thumbnailImage: string;
  }): Promise<void> {
    const now = firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp;
    return firebase
      .firestore()
      .collection(this.COLLECTION_NAME)
      .doc(data.storyId)
      .update({
        thumbnailImage: data.thumbnailImage,
        updated: now,
      });
  }

  static updateTitle(data: { storyId: string; title: string }): Promise<void> {
    const now = firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp;
    return firebase
      .firestore()
      .collection(this.COLLECTION_NAME)
      .doc(data.storyId)
      .update({
        title: data.title,
        updated: now,
      });
  }

  static delete(data: { storyId: string }): Promise<void> {
    const now = firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp;
    return firebase
      .firestore()
      .collection(this.COLLECTION_NAME)
      .doc(data.storyId)
      .update({
        delete: true,
        updated: now,
      });
  }
}
