
import * as firebase from "firebase";
import { WriteStorySentenceEntity } from "@6cuts/@dto/write.story";

export class FSWriteStorySentenceRepository {
    static readonly COLLECTION_NAME_FIRST: string = "write.story";
    static readonly COLLECTION_NAME_SECOND: string = "write.sentence";

    static createSentence(data: {
        storyId: string;
        sentence: WriteStorySentenceEntity;
    }): Promise<string> {
        const now = firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp;
        return firebase.firestore()
            .collection(this.COLLECTION_NAME_FIRST)
            .doc(data.storyId)
            .collection(this.COLLECTION_NAME_SECOND)
            .add({
                mediaId: data.sentence.mediaId,
                mediaType: data.sentence.mediaType,
                texts: data.sentence.texts,
                created: now,
                updated: now,
            })
            .then(
                (
                    ref: firebase.firestore.DocumentReference<
                        firebase.firestore.DocumentData
                    >
                ) => {
                    return ref.id;
                }
            );
    }

    static updateSentence(data: {
        storyId: string;
        sentence: WriteStorySentenceEntity;
    }): Promise<void> {
        const now = firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp;

        console.log("data.storyId : " + data.storyId);
        console.log("data.sentence.id : " + data.sentence.id);
        return firebase.firestore()
            .collection(this.COLLECTION_NAME_FIRST)
            .doc(data.storyId)
            .collection(this.COLLECTION_NAME_SECOND)
            .doc(data.sentence.id)
            .update({
                mediaId: data.sentence.mediaId,
                mediaType: data.sentence.mediaType,
                texts: data.sentence.texts,
                created: data.sentence.created,
                updated: now,
            });
    }
}
