import React, { useState, useRef } from "react";
import { StoryContainer } from "../story.container";
import "./story-main.component.scss";
import { StorySelectComponent } from "./story-select/story-select.component";
import { StoryKeywordComponent } from "./story-keyword/story-keyword.component";
import { StoryAddPointComponent } from "./story-add-point/story-add-point.component";
import { StoryVideoComponent } from "./story-video/story-video.component";
import { StoryHeaderComponent } from "./story-header/story-header.component";
import { StorySoundComponent } from "./story-sound/story-sound.component";
import { YesNoAlertComponent } from "../../../common/alert/alert";

/*
 * ストーリーメイン
 */
export const StoryMainComponent: React.FC = () => {
  return (
    <div className="container">
      <StoryVideoComponent />
      <StoryAddPointComponent />
      <StoryActionComponent />
      <StoryHeaderComponent />
      <StoryExplanationTextComponent />
      <StoryEndDialog />
      <StorySoundComponent />
    </div>
  );
};

/*
 * ストーリーアクションコンポーネント
 */
const StoryActionComponent: React.FC = () => {
  const storyContainer = StoryContainer.useContainer();
  if (!storyContainer.isVideoEnd) {
    return null;
  }
  switch (storyContainer.currentChapter?.actionType) {
    case "select":
      return (
        <StorySelectComponent
          selectList={storyContainer.currentChapter?.select}
        />
      );
    case "keyword":
      return (
        <StoryKeywordComponent
          keywordList={storyContainer.currentChapter?.keyword}
        />
      );
    case "end":
    default:
      return null;
  }
};

const StoryEndDialog: React.FC = () => {
  const storyContainer = StoryContainer.useContainer();
  const onClickYes = () => {
    storyContainer.setIsStart(false);
    storyContainer.storyReset();
  };
  const onClickNo = () => {
    storyContainer.setIsEndDialog(false);
  };
  return (
    <YesNoAlertComponent
      title="確認"
      message="終了しますか？"
      yesCallback={onClickYes}
      noCallback={onClickNo}
      isOpen={storyContainer.isEndDialog}
    />
  );
};

/*
 * 説明表示コンポーネント
 */
const StoryExplanationTextComponent: React.FC = () => {
  const storyContainer = StoryContainer.useContainer();
  if (!storyContainer.isVideoEnd) {
    return null;
  }
  return (
    <div>
      {!storyContainer.isFullScreen && (
        <div className="explanation is-size-6 has-text-white has-text-centered has-text-weight-bold">
          {storyContainer.currentChapter?.explanation}
        </div>
      )}
      <div className="tap-text has-text-white">
        ボタン以外タップで非表示切替
      </div>
    </div>
  );
};
