import * as firebase from 'firebase'
import { docData } from 'rxfire/firestore';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


import { ReadOwnUserEntity } from "@6cuts/@dto/read.own-user";


export class FSReadOwnUserRepository {
    static readonly COLLECTION_NAME: string = "read.own_user";

    static isUserEntity = (arg: any) => {
        return arg.id !== undefined
    }

    static watchUser(
        data: {
            userId: string
        }
    ): Observable<ReadOwnUserEntity | null> {
        const docRef = firebase.firestore()
            .collection(this.COLLECTION_NAME)
            .doc(data.userId) as firebase.firestore.DocumentReference<ReadOwnUserEntity>

        return docData<ReadOwnUserEntity>(docRef)
            .pipe(
                map((entity: ReadOwnUserEntity) => {
                    if (entity.id === undefined) {
                        return null
                    }

                    const user: ReadOwnUserEntity = {
                        id: entity.id,
                        nickname: entity.nickname,
                        isExistIcon: entity.isExistIcon,
                        gender: entity.gender,
                        birthday: entity.birthday,
                        created: entity.created,
                        updated: entity.updated,
                    }
                    return user
                })
            )
    }
}