import * as firebase from "firebase"
import { v4 as uuid4 } from "uuid";
import { storageEnvironment } from "environment/storage_env";

export class CSStoryImageRepository {
  static directoryPath = (storyId: string) => {
    return `stories/${storyId}`;
  };

  /**
   * ストーリーのタイトルサムネイル画像の取得
   * @param data
   */
  static getStoryThumbnailImageUrl(data: { storyId: string }): Promise<string> {
    const ref = firebase.storage().ref(
      `${this.directoryPath(data.storyId)}/${data.storyId}_thumbnail`
    );
    return ref
      .getDownloadURL()
      .then((value: any) => {
        const url: string = value as string;
        return url;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  }

  /**
   * 一般公開されてるサムネイル画像の取得
   * @param storyId 
   * @param imageId 
   */
  static getPublicStoryImageUrl(
    data: {
      storyId: string,
      imageId: string
    }
  ): string {
    const path: string = `/stories/${data.storyId}/${data.imageId}`;
    return `https://storage.googleapis.com/${storageEnvironment.publicStorage}${path}`;
  }

  static upload(data: { storyId: string; file: File }): Promise<boolean> {
    const ref = firebase.storage().ref(
      `${this.directoryPath(data.storyId)}/${data.storyId}`
    );
    return ref
      .put(data.file)
      .then((_) => {
        return true;
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
