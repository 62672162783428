import { useHistory } from "react-router-dom";
import React from "react";

export interface NaviComponentProps {
  title?: string;
}

export const NaviComponent: React.FC<NaviComponentProps> = (
  props: NaviComponentProps
) => {
  const history = useHistory();

  return (
    <nav
      className="navbar is-primary"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <a
          className="navbar-item"
          onClick={() => {
            history.push("/top");
          }}
        >
          <figure className="image is-24x24 is-1by1">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/6cuts_top_icon.png`}
            />
          </figure>
        </a>
        <span className="navbar-item has-text-white">{props.title}</span>
      </div>
    </nav>
  );
};
