import * as firebase from "firebase";

// -----------------------------------
//
//              STORY
//
// -----------------------------------
export interface WriteStoryEntity {
  id: string;
  ownerId: string;
  title: string;
  price: number;
  scoreEnable: boolean;
  scoreVisible: boolean;
  scoreName: string;
  scoreUnit: string;
  initScore: number;
  billingChapterId: string;
  numberOfFreePlays: number;
  thumbnailImage: string;
  published: boolean;
  delete: boolean;
  titleVisible: boolean;
  created: firebase.firestore.Timestamp;
  updated: firebase.firestore.Timestamp;
}

// -----------------------------------
//
//              CHAPTER
//
// -----------------------------------
export enum ChapterActionType {
  NONE = "none",
  SELECT = "select",
  KEYWORD = "keyword",
  END = "end",
}

/**
 * Chapter
 */
export interface WriteStoryChapterEntity {
  id: string;
  chapterId: string;
  sentenceIdList: string[];
  actionType: ChapterActionType;
  explanation: string;
  select: ChapterActionSelect[];
  keyword: ChapterKeyWord;
  created: firebase.firestore.Timestamp;
  updated: firebase.firestore.Timestamp;
}

export interface ChapterActionSelect {
  text: string;
  point: number;
  selectPointLimit: number;
  transitionId: string;
}

export interface ChapterKeyWord {
  answer: ChapterActionKeyword[];
  incorrect: ChapterActionKeyword;
}

export interface ChapterActionKeyword {
  text: string;
  point: number;
  transitionId: string;
}

// -----------------------------------
//
//              SENTENCE
//
// -----------------------------------
export enum SentenceMediaType {
  NONE = "none",
  VIDEO = "video",
  IMAGE = "image",
  TEXT = "text",
}

export interface WriteStorySentenceEntity {
  id: string;
  mediaId: string;
  mediaType: SentenceMediaType;
  texts: string[];
  created: firebase.firestore.Timestamp;
  updated: firebase.firestore.Timestamp;
}
