import React, { useRef } from "react";
import "./story-add-point.component.scss";
import { StoryContainer } from "../../story.container";

/*
 * ポイント表示
 */

export const StoryAddPointComponent: React.FC = () => {
  const storyContainer = StoryContainer.useContainer();

  if (storyContainer.videoUrl == "" || storyContainer.additionalPoint == 0) {
    return null;
  }

  if (storyContainer.additionalPoint > 0) {
    return (
      <div className="addPoint-wrapper">
        <div className="point-add is-size-4 is-italic has-text-white has-text-centered has-text-weight-bold">
          {storyContainer.story?.scoreName} + {storyContainer.additionalPoint}
        </div>
      </div>
    );
  } else {
    return (
      <div className="addPoint-wrapper">
        <div className="point-subtract is-size-4 is-italic has-text-white has-text-centered has-text-weight-bold">
          {storyContainer.story?.scoreName} -
          {Math.abs(storyContainer.additionalPoint)}
        </div>
      </div>
    );
  }
};
