import React, { useState, useEffect } from "react";
import styles from "./rule-page.module.scss";
import { useHistory, useParams } from "react-router-dom";
import { getMaxChapterNumber } from "../chapter-list/chapter-list-page";
import { FSReadStoryRepository } from "@6cuts/public-repository/read.story";
import { ReadStoryEntity } from "@6cuts/@dto/read.story";
import { WriteStoryEntity } from "@6cuts/@dto/write.story";
import { FSWriteStoryRepository } from "@6cuts/public-repository/write.story";
import { LoadingModalComponent } from "components/common/loading/loading";

export const StoryRulePageComponent: React.FC = () => {
  const history = useHistory();

  const { storyId } = useParams();

  const [story, setStory] = useState<ReadStoryEntity | null>(null);
  const [price, setPrice] = useState<number>(0);
  const [initScore, setInitScore] = useState<number>(0);
  const [scoreName, setScoreName] = useState<string>("");
  const [scoreUnit, setScoreUnit] = useState<string>("");
  const [billingChapterId, setBillingChapterId] = useState<string>("");
  const [numberOfFreePlays, setNumberOfFreePlays] = useState<string>("");
  const [scoreEnable, setScoreEnable] = useState<boolean>(false);
  const [initScoreValid, setInitScoreValid] = useState<boolean>(true);
  const [isReleaseLoading, setIsReleaseLoading] = useState<boolean>(false);
  const [scoreVisible, setScoreVisible] = useState<boolean>(true);
  const [titleVisible, setTitleVisible] = useState<boolean>(true);

  useEffect(() => {
    if (storyId === "") {
      return;
    }
    const subscription = FSReadStoryRepository.watchStory({
      storyId: storyId,
    }).subscribe((storyData: ReadStoryEntity | null) => {
      if (storyData !== null) {
        setStory(storyData);
        setPrice(storyData.price);
        setInitScore(storyData.initScore);
        setScoreName(storyData.scoreName);
        setScoreUnit(storyData.scoreUnit);
        setBillingChapterId(storyData.billingChapterId);
        setNumberOfFreePlays(storyData.numberOfFreePlays.toString());
        setScoreEnable(storyData.scoreEnable);
        setScoreVisible(storyData.scoreVisible);
        setTitleVisible(storyData.titleVisible);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [storyId]);

  let chapterListNo = [];
  for (var i = 0; i < getMaxChapterNumber(); i++) {
    chapterListNo.push(<option value={(i + 1).toString()}>{i + 1}</option>);
  }

  // もどるボタンの変更ハンドラ
  const onClickBackPageHandler = () => {
    history.goBack();
  };

  // 保存ボタンの変更ハンドラ
  const onClickSaveHandler = () => {
    if (!initScoreValid) {
      return;
    }
    if (story !== null) {
      const writeStory: WriteStoryEntity = {
        id: story.id,
        ownerId: story.ownerId,
        title: story.title,
        price: price,
        scoreEnable: scoreEnable,
        scoreVisible: scoreVisible,
        scoreName: scoreName,
        scoreUnit: scoreUnit,
        initScore: initScore,
        thumbnailImage: story.thumbnailImage,
        billingChapterId: billingChapterId,
        numberOfFreePlays: Number(numberOfFreePlays),
        published: story.published,
        delete: story.delete,
        titleVisible: titleVisible,
        created: story.created,
        updated: story.updated,
      };

      FSWriteStoryRepository.updateStory(writeStory).then((_) => {
        setIsReleaseLoading(true);
      });
    }
  };

  // 値段の変更ハンドラ
  const onChangeRadioPriceHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPrice(Number(event.target.value));
  };

  // スコア表記有無の変更ハンドラ
  const onChangeRadioScoreEnableHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setScoreEnable(Number(event.target.value) !== 0);
  };
  // スコアの非表示の変更ハンドラ
  const onChangeRadioScoreVisibleHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setScoreVisible(Number(event.target.value) !== 0);
  };
  // タイトルの非表示の変更ハンドラ
  const onChangeRadioTitleVisibleHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTitleVisible(Number(event.target.value) !== 0);
  };

  // 初期スコアの変更ハンドラ
  const onChangeInitScoreHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = Number(event.target.value);
    setInitScoreValid(value >= -10000 && value <= 10000);
    setInitScore(value);
  };

  // スコア名の変更ハンドラ
  const onChangeScoreNameHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value.length <= 20) {
      setScoreName(event.target.value);
    }
  };

  // 単位の変更ハンドラ
  const onChangeScoreUnitHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value.length <= 20) {
      setScoreUnit(event.target.value);
    }
  };

  const onChangeBillingChapterIdHandler = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setBillingChapterId(event.target.value);
  };

  const onChangeNumberOfFreePlaysHandler = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setNumberOfFreePlays(event.target.value);
  };

  return (
    <div className={styles.page}>
      <div className="header">
        <div className="columns is-gapless is-mobile">
          <div className="column is-half">
            <label className="title">ルール設定</label>
          </div>
          <div className="btn-list column">
            <button className="button" onClick={onClickBackPageHandler}>
              もどる
            </button>
            <button className="button" onClick={onClickSaveHandler}>
              保存
            </button>
          </div>
        </div>
      </div>
      <section className="section">
        <div className="container">
          {!initScoreValid && (
            <div className="invalid has-text-danger">
              入力項目が正しくありません。
            </div>
          )}
          <div className="price">
            <div className="title is-size-5">１プレイの価格</div>
            <label>
              <ul>
                <li>
                  <label>
                    <input
                      type="radio"
                      className="radio-input"
                      value="0"
                      name="price"
                      checked={price === 0}
                      onChange={onChangeRadioPriceHandler}
                    />
                    <span className="radio-span">無料</span>
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type="radio"
                      className="radio-input"
                      value="300"
                      name="price"
                      checked={price === 300}
                      onChange={onChangeRadioPriceHandler}
                    />
                    <span className="radio-span">300円</span>
                  </label>
                  <label>
                    <input
                      type="radio"
                      className="radio-input"
                      value="500"
                      name="price"
                      checked={price === 500}
                      onChange={onChangeRadioPriceHandler}
                    />
                    <span className="radio-span">500円</span>
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type="radio"
                      className="radio-input"
                      value="1000"
                      name="price"
                      checked={price === 1000}
                      onChange={onChangeRadioPriceHandler}
                    />
                    <span className="radio-span">1000円</span>
                  </label>
                  <label>
                    <input
                      type="radio"
                      className="radio-input"
                      value="1500"
                      name="price"
                      checked={price === 1500}
                      onChange={onChangeRadioPriceHandler}
                    />
                    <span className="radio-span">1500円</span>
                  </label>
                </li>
              </ul>
              {price !== 0 && (
                <div>
                  <div className="id-select">
                    <div className="title is-size-5">課金チャプターID</div>
                    <div className="select is-primary">
                      <select
                        value={billingChapterId}
                        onChange={(e) => onChangeBillingChapterIdHandler(e)}
                      >
                        {billingChapterId === "" && (
                          <option value="選択してください"></option>
                        )}
                        {chapterListNo}
                      </select>
                    </div>
                    <label className="caution">
                      ※指定したチャプターのアクション実行時に課金ダイアログを表示。
                      ※避けるルートが存在する場合、課金ダイアログは実行されません。
                    </label>
                  </div>
                  <div className="id-select">
                    <div className="title is-size-5">無料プレイ回数</div>
                    <div className="select is-primary">
                      <select
                        value={numberOfFreePlays}
                        onChange={(e) => onChangeNumberOfFreePlaysHandler(e)}
                      >
                        <option value="0">0</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                    <label className="caution">
                      ※指定した回数に達成するまでは無料でプレイすることができます。
                    </label>
                  </div>
                </div>
              )}
            </label>
          </div>
          <div className="title-visible">
            <div className="title is-size-5">タイトルテキスト</div>
            <div className="score-radio">
              <label>
                <input
                  type="radio"
                  className="radio-input"
                  value="1"
                  name="title-visible"
                  checked={titleVisible === true}
                  onChange={onChangeRadioTitleVisibleHandler}
                />
                <span className="radio-span">表示</span>
              </label>
              <label>
                <input
                  type="radio"
                  className="radio-input"
                  value="0"
                  name="title-visible"
                  checked={titleVisible === false}
                  onChange={onChangeRadioTitleVisibleHandler}
                />
                <span className="radio-span">非表示</span>
              </label>
            </div>
          </div>
          <div className="score">
            <div className="title is-size-5">スコアの利用</div>
            <div className="score-radio">
              <label>
                <input
                  type="radio"
                  className="radio-input"
                  value="1"
                  name="score-enable"
                  checked={scoreEnable === true}
                  onChange={onChangeRadioScoreEnableHandler}
                />
                <span className="radio-span">あり</span>
              </label>
              <label>
                <input
                  type="radio"
                  className="radio-input"
                  value="0"
                  name="score-enable"
                  checked={scoreEnable === false}
                  onChange={onChangeRadioScoreEnableHandler}
                />
                <span className="radio-span">なし</span>
              </label>
            </div>

            {scoreEnable && (
              <div>
                <div className="input-text">
                  <div className="title is-size-5">スコア名</div>
                  <input
                    className="input"
                    type="text"
                    maxLength={5}
                    value={scoreName.toString()}
                    onChange={onChangeScoreNameHandler}
                  />
                  <label className="caution">
                    ※5文字以下(得点,好感度,ポイント,体力,資金etc.)
                  </label>
                </div>
                <div className="input-text">
                  <div className="title is-size-5">スコアの単位</div>
                  <input
                    className="input"
                    type="text"
                    maxLength={5}
                    value={scoreUnit.toString()}
                    onChange={onChangeScoreUnitHandler}
                  />
                  <label className="caution">
                    ※5文字以下(個、回、％、円etc.)
                  </label>
                </div>
                <div className="input-text">
                  <div className="title is-size-5">スタート時のスコア</div>
                  <input
                    className={[
                      "input",
                      initScoreValid ? "" : "is-danger",
                    ].join(" ")}
                    type="number"
                    value={initScore.toString()}
                    onChange={onChangeInitScoreHandler}
                  />
                  <label className="caution">※-10000~10000で設定</label>
                </div>

                <div className="title is-size-5">スコアの表示</div>
                <div className="score-radio">
                  <label>
                    <input
                      type="radio"
                      className="radio-input"
                      value="1"
                      name="score-visible"
                      checked={scoreVisible === true}
                      onChange={onChangeRadioScoreVisibleHandler}
                    />
                    <span className="radio-span">あり</span>
                  </label>
                  <label>
                    <input
                      type="radio"
                      className="radio-input"
                      value="0"
                      name="score-visible"
                      checked={scoreVisible === false}
                      onChange={onChangeRadioScoreVisibleHandler}
                    />
                    <span className="radio-span">なし</span>
                  </label>
                  <label className="caution">
                    ※なしにした場合、クリアするまでスコアが見えません。
                  </label>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      <LoadingModalComponent
        title="保存中"
        isOpen={isReleaseLoading}
        second={3000}
        timerCallback={() => {
          history.goBack();
          setIsReleaseLoading(false);
        }}
      />
    </div>
  );
};
