import styles from "./chapter-cell.module.scss";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import React from "react";
import { ReadChapter } from "@6cuts/@dto/read.story";
import { CSUserVideoRepository } from "@6cuts/storage/users/video";
import {
  ChapterActionType,
  ChapterActionSelect,
  ChapterActionKeyword,
} from "@6cuts/@dto/write.story";

interface ChapterCellProps {
  userId: string;
  storyId: string;
  number: number;
  chapterData: ReadChapter | null;
  chapters: (ReadChapter | null)[];
  onClickVideoPreviewHandler: (chapter: ReadChapter) => void
}

export const ChapterCellComponent: React.FC<ChapterCellProps> = (props) => {
  const history = useHistory();
  const [thumbnailImageUrl, setThumbnailImageUrl] = useState<string>("");

  let beforeTransition: JSX.Element[] = [];

  props.chapters.forEach((chapter) => {
    if (chapter !== null) {
      //遷移元を取得
      const selects = chapter.select.filter((select: ChapterActionSelect) => {
        return select.transitionId === props.number.toString();
      });
      const keywords = chapter.keyword.answer.filter(
        (keyword: ChapterActionKeyword) => {
          return keyword.transitionId === props.number.toString();
        }
      );
      if (
        selects.length > 0 ||
        keywords.length > 0 ||
        chapter.keyword.incorrect.transitionId === props.number.toString()
      ) {
        beforeTransition.push(
          <label className="id">{("000" + chapter.chapterId).slice(-3)}</label>
        );
      }
    }
  });

  // //遷移先の取得
  let afterTransition: JSX.Element[] = [];
  let after: string[] = [];
  if (props.chapterData !== null) {
    props.chapterData.select.forEach((select: ChapterActionSelect) => {
      after.push(select.transitionId);
    });
    props.chapterData.keyword.answer.forEach(
      (keyword: ChapterActionKeyword) => {
        after.push(keyword.transitionId);
      }
    );
    if (props.chapterData.keyword.incorrect.transitionId !== "") {
      after.push(props.chapterData.keyword.incorrect.transitionId);
    }
  }
  after = after.filter((value, index, self) => self.indexOf(value) === index);
  after.forEach((transitionId: string) => {
    if (transitionId !== "") {
      afterTransition.push(<label>{("000" + transitionId).slice(-3)}</label>);
    }
  });

  useEffect(() => {
    if (
      props.chapterData !== null &&
      props.chapterData.sentenceList.length > 0
    ) {
      CSUserVideoRepository.getThumbnailImage({
        userId: props.userId,
        mediaId: props.chapterData.sentenceList[0].mediaId,
      }).then((url: string) => {
        setThumbnailImageUrl(url);
      });
    }
  }, [props.chapterData]);

  const onClickChapterHandler = () => {
    history.push(
      `/story/edit/chapter-list/chapter/${props.storyId}/${props.number}`
    );
  };

  const onClickChapterThumbnailImage = () => {
    if (props.chapterData === null) {
      onClickChapterHandler()
      return
    }
    props.onClickVideoPreviewHandler(props.chapterData)
  }

  return (
    <div className={styles.chapter}>
      <div className="before-id">
        {props.number === 1 && <label className="start">START</label>}
        {props.number !== 1 && beforeTransition}
      </div>
      <div
        className="contents columns is-mobile is-gapless"
      >
        <div className="thumbnail colum" onClick={onClickChapterThumbnailImage}>
          {thumbnailImageUrl === "" && <div className="black-image"></div>}
          {thumbnailImageUrl !== "" && <img src={thumbnailImageUrl} />}
        </div>
        <div className="chapter-info" onClick={onClickChapterHandler}>
          <div className="chapter-id colum">
            <label>{("000" + props.number).slice(-3)}</label>
          </div>
          <div className="action-text colum">
            <label>
              {props.chapterData !== null ? props.chapterData.explanation : ""}
            </label>
          </div>
        </div>
      </div>
      <div className="after-id">
        {afterTransition}
        {props.chapterData?.actionType === ChapterActionType.END && (
          <label className="end">END</label>
        )}
        {beforeTransition.length > 0 &&
          afterTransition.length === 0 &&
          props.chapterData?.actionType !== ChapterActionType.END && (
            <label className="warning"> ⚠ </label>
          )}
      </div>
    </div>
  );
};
