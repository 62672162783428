
export class TimeCalculator {

    static toString(
        data: {
            time: number,
            hour: boolean,
            min: boolean,
            sec: boolean,
        }
    ): string {
        const zeroPadding = (num: number, length: number): string => {
            return ('0000000000' + num).slice(-length);
        }

        const hour: number = Math.floor(data.time / 3600)
        const min: number = Math.floor((data.time - hour * 3600) / 60)
        const sec: number = data.time - hour * 3600 - min * 60

        const hourStr: string = zeroPadding(hour, 2)
        const minStr: string = zeroPadding(min, 2)
        const secStr: string = zeroPadding(sec, 2)

        if (data.hour && data.min && data.sec) {
            return `${hourStr}:${minStr}:${secStr}`

        } else if (data.hour && data.min && !data.sec) {
            return `${hourStr}:${minStr}`

        } else if (data.hour && !data.min && data.sec) {
            // これはあり得ないと思うので全部表示
            return `${hourStr}:${minStr}:${secStr}`

        } else if (data.hour && !data.min && !data.sec) {
            return `${hourStr}`

        } else if (!data.hour && data.min && data.sec) {
            return `${zeroPadding(hour * 60 + min, 2)}:${secStr}`

        } else if (!data.hour && data.min && !data.sec) {
            return `${zeroPadding(hour * 60 + min, 2)}`

        } else if (!data.hour && !data.min && data.sec) {
            return `${zeroPadding(hour * 3600 + min * 60 + sec, 2)}`

        } else {
            return ""
        }
    }
}