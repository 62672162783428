import React from "react";
import { StoryContainer } from "./story.container";
import { StoryTopComponent } from "./story-top/story-top-component";
import { StoryMainComponent } from "./story-main/story-main.component";
import "./story.component.scss";
import { useParams } from "react-router-dom";

/*
 * ストーリーページ本体
 */
export const StoryComponent: React.FC = () => {
  const { storyId } = useParams();

  return (
    <StoryContainer.Provider initialState={storyId}>
      <StoryOperate />
    </StoryContainer.Provider>
  );
};

const StoryOperate: React.FC = () => {
  const storyContainer = StoryContainer.useContainer();

  if (storyContainer.isStoryAvailable === false) {
    return (
      <div className="story-page">
        <div className="black-mask">
          <div className="story-not-available">
            <p className="subtitle has-text-white">このコンテンツは</p>
            <p className="subtitle has-text-white">現在利用できません</p>
          </div>
        </div>
      </div>
    )
  }
  if (storyContainer.isStart) {
    return (
      <StoryMainComponent />
    );
  } else {
    return (
      <StoryTopComponent />
    );
  }
};
