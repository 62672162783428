import { useState, useEffect, useRef } from "react";
import { createContainer } from "unstated-next";
import firebase from "firebase";
import { AppContainer } from "../app/app.container";
import { SentenceMediaType, WriteStorySentenceEntity } from "@6cuts/@dto/write.story";
import { CSUserVideoRepository } from "@6cuts/storage/users/video";
import { ReadSentence } from "@6cuts/@dto/read.story";

const useSentenceContainer = (initialState: ReadSentence[] = []) => {
  const [sentenceList, setSentenceList] = useState<ReadSentence[]>(
    initialState ? initialState : []
  );
  const [mediaId, setMediaId] = useState<string>("");
  const [mediaType, setMediaType] = useState<SentenceMediaType>(SentenceMediaType.NONE);
  const [texts, setTexts] = useState<string[]>([]);
  const [isVideoModal, setIsVideoModal] = useState<boolean>(false);

  useEffect(() => {
    setSentenceList(initialState);
    if (initialState.length > 0) {
      const sentence = initialState[0];
      setMediaId(sentence.mediaId);
      setMediaType(sentence.mediaType);
      setTexts(sentence.texts);
    }
  }, [initialState]);

  const setMediaInfo = (data: {
    mediaId: string;
    mediaType: SentenceMediaType;
  }) => {
    setMediaId(data.mediaId);
    setMediaType(data.mediaType);
  };

  const getSentence = () => {
    const now = firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp;
    const data: WriteStorySentenceEntity = {
      id: sentenceList.length > 0 ? sentenceList[0].id : "",
      mediaId: mediaId,
      mediaType: mediaType,
      texts: texts,
      created: now,
      updated: now,
    };
    return data;
  };

  return {
    mediaId,
    isVideoModal,
    setMediaInfo,
    setIsVideoModal,
    getSentence,
  };
};
export const SentenceContainer = createContainer(useSentenceContainer);
