import { useState, useEffect, useRef } from "react";
import { createContainer } from "unstated-next";
import { v4 as uuid4 } from "uuid";

import { ReadStoryEntity, ReadChapter } from "@6cuts/@dto/read.story";
import { FSReadStoryRepository } from "@6cuts/public-repository/read.story";
import { CSUserVideoRepository } from "@6cuts/storage/users/video";
import { CSStoryImageRepository } from "@6cuts/storage/story/image";

const useStoryContainer = (initialState = "") => {
  // とりあえずIDはベタガキ
  const storyId: string = initialState;
  // プレイデータを特定するためのID
  const playId: string = uuid4();
  // ストーリー
  const [story, setStory] = useState<ReadStoryEntity>();
  // // レビュー
  // const [reviewList, setReviewList] = useState<FSStoryReviewEntity[]>([]);
  // ストーリーの現在進行データ
  const [currentChapter, setCurrentChapter] = useState<ReadChapter>();
  // 動画のダウンロードURL
  const [videoUrl, setVideoUrl] = useState<string>();
  // TOP画像のダウンロードURL
  const [titleImageUrl, setTitleImageUrl] = useState<string>();
  // スコアポイント
  const [scorePoint, setScorePoint] = useState<number>(0);
  // 追加されたポイント
  const [additionalPoint, setAdditionalPoint] = useState<number>(0);
  // ストーリー開始フラグ
  const [isStart, setIsStart] = useState<boolean>(false);
  // 動画再生中フラグ
  const [isPlayVideo, setIsPlayVideo] = useState<boolean>(false);
  // 動画終了フラグ
  const [isVideoEnd, setIsVideoEnd] = useState<boolean>(false);
  // 動画エレメント
  const videoEl = useRef<HTMLVideoElement>(null);
  // ストーリーダイアログの表示フラグ
  const [isEndDialog, setIsEndDialog] = useState<boolean>(false);
  // ストーリーが利用可能か
  const [isStoryAvailable, setIsStoryAvailable] = useState<boolean>(true);
  // リプレイ可能かどうか
  const [isReplayEnabled, setIsReplayEnabled] = useState<boolean>(true);
  // フルスクリーン切り替え
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);

  //効果音
  const addPointAudioRef = useRef<HTMLAudioElement>(null);
  const subtractPointAudioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    FSReadStoryRepository.watchStory({
      storyId: initialState,
    }).subscribe((storyData: ReadStoryEntity | null) => {
      if (storyData === null) {
        setIsStoryAvailable(false);
        return;
      }
      setStory(storyData);
      setCurrentChapter(storyData.chapterList[0]);
      if (storyData.scoreEnable) {
        setScorePoint(storyData.initScore);
      }
      //タイトル画像の取得
      CSStoryImageRepository.getStoryThumbnailImageUrl({
        storyId: storyData.id,
      }).then((url: string) => {
        setTitleImageUrl(url);
        console.log(url);
      });

      // レビュー情報の取得
      // FSGetReviewService.getReviewList({
      //   storyId: storyData.id,
      // }).then((reviewList: FSStoryReviewEntity[]) => {
      //   setReviewList(reviewList);
      // });
    });
  }, [initialState]);

  useEffect(() => {
    if (storyId === "" || currentChapter === undefined) {
      return;
    }
    setIsPlayVideo(true);
    setIsFullScreen(false);
    //動画の取得
    CSUserVideoRepository.getVideoUrl({
      userId: story!.ownerId,
      mediaId: currentChapter.sentenceList[0].mediaId,
    }).then((url: string) => {
      setVideoUrl(url);
      console.log(url);
    });
  }, [currentChapter]);

  //次のストーリーにいく
  const moveToNextChapter = (nextChapterId: string) => {
    if (story) {
      story.chapterList.some((chapter: ReadChapter) => {
        if (chapter.chapterId == nextChapterId) {
          setVideoUrl("");
          setCurrentChapter(chapter);
          //フラグを初期化
          setIsVideoEnd(false);

          return true;
        }
      });
    }
  };

  //スコアポイントの加算
  const addScorePoint = (amount: number) => {
    if (story?.scoreEnable) {
      setScorePoint((point: number) => point + amount);
      setAdditionalPoint(amount);
    }
  };

  //再生中フラグの切り替え
  const switchPlayVideo = () => {
    setIsPlayVideo((isPlay: boolean) => !isPlay);
  };

  //フルスクリーン切り替え
  const switchFullScreen = () => {
    setIsFullScreen((isFullScreen: boolean) => !isFullScreen);
  };

  //ストーリーの初期化
  const storyReset = () => {
    if (story) {
      setCurrentChapter(story.chapterList[0]);
      if (story.scoreEnable) {
        setScorePoint(story.initScore);
      }
    }
    setAdditionalPoint(0);
    setIsEndDialog(false);
    setIsStart(false);
    setIsVideoEnd(false);
    setIsFullScreen(false);
    setIsReplayEnabled(true);
    setIsPlayVideo(true);
  };

  //スコアサウンドの再生
  const playScorePoint = (point: number) => {
    if (story?.scoreEnable) {
      if (point > 0) {
        playAddPointSound();
      } else if (point < 0) {
        playSubtractPointSound();
      } else {
      }
    }
  };

  const playAddPointSound = () => {
    if (addPointAudioRef.current) {
      addPointAudioRef.current.load();
      addPointAudioRef.current.play();
    }
  };
  const playSubtractPointSound = () => {
    if (subtractPointAudioRef.current) {
      subtractPointAudioRef.current.load();
      subtractPointAudioRef.current.play();
    }
  };

  const setReplayDisabled = () => {
    setIsReplayEnabled(false);
  };

  return {
    story,
    // reviewList,
    playId,
    currentChapter,
    videoUrl,
    titleImageUrl,
    scorePoint,
    additionalPoint,
    isStart,
    isPlayVideo,
    isVideoEnd,
    isEndDialog,
    videoEl,
    isStoryAvailable,
    isReplayEnabled,
    isFullScreen,
    addPointAudioRef,
    subtractPointAudioRef,
    moveToNextChapter,
    addScorePoint,
    setIsVideoEnd,
    switchPlayVideo,
    switchFullScreen,
    setIsEndDialog,
    setIsStart,
    storyReset,
    setReplayDisabled,
    playScorePoint,
  };
};

export const StoryContainer = createContainer(useStoryContainer);
