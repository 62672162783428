import React, { useState } from "react";
import styles from "./page.module.scss";

import * as firebase from "firebase";

import { take } from "rxjs/operators";
import { useHistory } from "react-router";

import { AuthService } from "@6cuts/auth/auth-service";
import { AppContainer } from "../../../containers/app/app.container";
import { ReadOwnUserEntity } from "@6cuts/@dto/read.own-user";
import { FSReadOwnUserRepository } from "@6cuts/public-repository/read.own_user";

/*
 * サインイン
 */
export const SignInEmailPageComponent: React.FC = () => {
    const appContainer = AppContainer.useContainer();
    const history = useHistory();

    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");

    const onChangeEmailHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    const onChangePasswordHandler = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setPassword(event.target.value);
    };

    const onClickEmailSignInHandler = () => {
        AuthService.signInWithEmailAndPassword({
            email: email,
            password: password,
        })
            .then((user: firebase.User) => {
                if (user.emailVerified) {
                    FSReadOwnUserRepository.watchUser({ userId: user.uid })
                        .pipe(take(1))
                        .subscribe((userEntity: ReadOwnUserEntity | null) => {
                            if (userEntity !== null) {
                                // アカウントがあればトップへ
                                appContainer.setUser(userEntity);
                                history.push("/top");
                            } else {
                                // アカウントが存在しなければ作成画面へ
                                history.push("/account/edit");
                            }
                        });
                } else {
                    const result: boolean = window.confirm(
                        "このメールアドレスは認証が済んでいません。認証用メールを再送信しますか？"
                    );
                    if (result === true) {
                        user.sendEmailVerification({
                            url: `${window.location.origin}/account/edit`,
                        });
                        window.alert(
                            "指定頂いたメールアドレス宛に確認用のメールを送信しました。メール本文に記載しているリンクURLをクリックして、認証を完了させてください。"
                        );
                    }
                }
            })
            .catch((error) => {
                const message: string = error.message ? error.message : "ログインに失敗しました。通信状況をお確かめの上、再度お試し下さい。"
                if (error.code) {
                    switch (error.code) {
                        case "auth/invalid-email":
                            setErrorMessage("正しいメールアドレスを入力してください。");
                            break
                        default:
                            setErrorMessage("メールアドレスもしくはパスワードが間違っています。")
                            break
                    }
                } else {
                    setErrorMessage(message)
                }
            });
    };

    const onClickEmailSignUpHandler = () => {
        history.push("/sign-up/email");
    };

    return (
        <div className={styles.page}>
            <nav
                className="navbar is-primary"
                role="navigation"
                aria-label="main navigation"
            >
                <div className="navbar-brand">
                    <a className="navbar-item" onClick={() => { history.push("/sign-in") }}>
                        <figure className="image is-24x24 is-1by1">
                            <img
                                src={`${process.env.PUBLIC_URL}/assets/images/6cuts_top_icon.png`}
                            />
                        </figure>
                    </a>
                    <span className="navbar-item has-text-white">ログイン</span>
                </div>
            </nav>

            <section className="section">
                <div className="container">
                    <div className="field is-vertical">
                        <div className="field-label is-normal">
                            <label className="label">メールアドレス</label>
                        </div>
                        <div className="field-body">
                            <div className="field">
                                <p className="control">
                                    <input
                                        className="input"
                                        type="email"
                                        placeholder="Email"
                                        onChange={onChangeEmailHandler}
                                    />
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="field is-vertical">
                        <div className="field-label is-normal">
                            <label className="label">パスワード</label>
                        </div>
                        <div className="field-body">
                            <div className="field">
                                <p className="control">
                                    <input
                                        className="input"
                                        type="password"
                                        placeholder="Password"
                                        onChange={onChangePasswordHandler}
                                    />
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="error-message has-text-danger">{errorMessage}</div>
                    <div className="action">
                        <div>
                            <button
                                className="button is-outlined sign-in"
                                onClick={onClickEmailSignInHandler}
                            >
                                ログイン
                            </button>
                        </div>
                        <div>
                            <button
                                className="button is-text is-small sign-up"
                                onClick={onClickEmailSignUpHandler}
                            >
                                新規登録はこちら
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};
