import * as firebase from "firebase";

import { v4 as uuidv4 } from 'uuid'


export class FSWriteUserMediaRepository {

    static collectionName(data: { userId: string }): string {
        return `write.user/${data.userId}/media`
    }

    static create(
        data: {
            userId: string,
            fileName: string,
        }
    ): Promise<string> {
        console.log("update data")
        console.log(data)
        const docId: string = uuidv4()

        return firebase.firestore()
            .collection(this.collectionName({ userId: data.userId }))
            .doc(docId)
            .set({
                id: docId,
                userId: data.userId,
                status: "prepare",
                memo: data.fileName,
                duration: 0,
                created: firebase.firestore.FieldValue.serverTimestamp(),
                updated: firebase.firestore.FieldValue.serverTimestamp(),
            }).then(() => {
                return docId
            })
    }

    /**
     * メディアデータのメモ更新
     * @param data 
     */
    static updateMemo(
        data: {
            userId: string,
            mediaId: string,
            memo: string,
        }
    ): Promise<void> {
        return firebase.firestore()
            .collection(this.collectionName({ userId: data.userId }))
            .doc(data.mediaId)
            .update({
                memo: data.memo,
                updated: firebase.firestore.FieldValue.serverTimestamp(),
            })
    }

    /**
     * メディアデータのカラータグ更新
     * @param data 
     */
    static updateColor(
        data: {
            userId: string,
            mediaId: string,
            color: string,
        }
    ): Promise<void> {
        return firebase.firestore()
            .collection(this.collectionName({ userId: data.userId }))
            .doc(data.mediaId)
            .update({
                color: data.color,
                updated: firebase.firestore.FieldValue.serverTimestamp(),
            })
    }

    /**
     * メディアデータの削除
     * @param data 
     */
    static delete(
        data: {
            userId: string,
            mediaId: string,
        }
    ): Promise<void> {
        return firebase.firestore()
            .collection(this.collectionName({ userId: data.userId }))
            .doc(data.mediaId)
            .delete()
    }

}