import React, { useState } from "react";
import "./story-select.component.scss";
import { StoryContainer } from "../../story.container";
import { ChapterActionSelect } from "@6cuts/@dto/write.story";

interface StorySelectProps {
  selectList: ChapterActionSelect[];
}

export const StorySelectComponent: React.FC<StorySelectProps> = (
  props: StorySelectProps
) => {
  const storyContainer = StoryContainer.useContainer();

  const onClickScreen = () => {
    storyContainer.switchFullScreen();
  };

  const onClickSelectButton = (point: number, nextId: string) => {
    storyContainer.addScorePoint(point);
    storyContainer.moveToNextChapter(nextId);
    storyContainer.playScorePoint(point);
  };

  return (
    <div className="select-wrapper" onClick={onClickScreen}>
      {!storyContainer.isFullScreen && (
        <div className="select-list">
          {props.selectList.map((select) => (
            <button
              disabled={storyContainer.scorePoint < select.selectPointLimit}
              className="select-button button is-light"
              onClick={() =>
                onClickSelectButton(select.point, select.transitionId)
              }
            >
              {select.text}
              {select.selectPointLimit != 0 && (
                <div className="limit-point-text">
                  {storyContainer.story?.scoreName}
                  {select.selectPointLimit}以上
                </div>
              )}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};
