
import { docData } from 'rxfire/firestore';
import { authState } from 'rxfire/auth';
import { map } from 'rxjs/operators';
import * as firebase from "firebase";

import { Observable } from 'rxjs';

const isUserEntity = (arg: any) => {
    return arg.id !== undefined
}

export class FSWriteUserRepository {
    static readonly COLLECTION_NAME: string = "write.user";

    static create(
        data: {
            userId: string,
            nickname: string,
            gender: string,
            birthday: string,
            isExstIcon: boolean,
        }
    ): Promise<void> {
        console.log("update data")
        console.log(data)
        return firebase.firestore()
            .collection(this.COLLECTION_NAME)
            .doc(data.userId)
            .set({
                id: data.userId,
                nickname: data.nickname,
                gender: data.gender,
                birthday: data.birthday,
                isExistIcon: data.isExstIcon,
                created: firebase.firestore.FieldValue.serverTimestamp(),
                updated: firebase.firestore.FieldValue.serverTimestamp(),
            })
    }

    static update(
        data: {
            userId: string,
            nickname: string,
            gender: string,
            birthday: string,
            isExstIcon: boolean,
        }
    ): Promise<void> {

        return firebase.firestore()
            .collection(this.COLLECTION_NAME)
            .doc(data.userId)
            .update({
                nickname: data.nickname,
                gender: data.gender,
                birthday: data.birthday,
                isExistIcon: data.isExstIcon,
                updated: firebase.firestore.FieldValue.serverTimestamp(),
            })
    }
}