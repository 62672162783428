import * as firebase from "firebase";

export interface WriteEventEntity {
  userId: string;
  type: string;
  data: { [index: string]: any };

  created: firebase.firestore.Timestamp;
  updated: firebase.firestore.Timestamp;
}

export const EventType = {
  story: {
    clone: "clone",
    review: {
      star: "review.star",
      comment: "review.comment",
    },
    play_count: {
      play: "play",
      freePlay: "freePlay",
      purchase: "purchase",
    },
  },
};
