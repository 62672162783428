import { docData } from "rxfire/firestore";
import { map, take } from "rxjs/operators";

import { Observable } from "rxjs";
import { collectionData } from "rxfire/firestore";
import firebase from "firebase";
import { ReadStoryEntity } from "@6cuts/@dto/read.story";

const isStoryEntity = (arg: any) => {
  return arg.id !== undefined;
};

export class FSReadStoryRepository {
  static readonly COLLECTION_NAME: string = "read.story";

  /**
   * ストーリー情報の取得
   * @param data
   */
  static getStory(data: { storyId: string }): Promise<ReadStoryEntity | null> {
    return this.watchStory({ storyId: data.storyId }).pipe(take(1)).toPromise();
  }

  /**
   * ストーリー情報の監視
   * @param data
   */
  static watchStory(data: {
    storyId: string;
  }): Observable<ReadStoryEntity | null> {
    const docRef = firebase
      .firestore()
      .collection(this.COLLECTION_NAME)
      .doc(data.storyId) as firebase.firestore.DocumentReference<
      ReadStoryEntity
    >;

    return docData<ReadStoryEntity>(docRef).pipe(
      map((entity: ReadStoryEntity) => {
        if (!isStoryEntity(entity)) {
          return null;
        }
        if (entity.delete) {
          return null;
        }
        const story: ReadStoryEntity = {
          id: docRef.id,
          ownerId: entity.ownerId,
          title: entity.title,
          price: entity.price,
          scoreEnable: entity.scoreEnable,
          scoreVisible: entity.scoreVisible,
          scoreName: entity.scoreName,
          scoreUnit: entity.scoreUnit,
          initScore: entity.initScore,
          thumbnailImage: entity.thumbnailImage,
          billingChapterId: entity.billingChapterId,
          numberOfFreePlays: entity.numberOfFreePlays,
          chapterList: entity.chapterList,
          published: entity.published,
          delete: entity.delete,
          titleVisible: entity.titleVisible,
          created: entity.created,
          updated: entity.updated,
        };
        return story;
      })
    );
  }

  static watchStoryList(data: {
    userId: string;
  }): Observable<ReadStoryEntity[] | null> {
    const collectionRef = firebase
      .firestore()
      .collection(this.COLLECTION_NAME)
      .where("delete", "==", false)
      .where("ownerId", "==", data.userId)
      .orderBy("created", "desc");

    return collectionData<ReadStoryEntity>(collectionRef).pipe(
      map((stories: ReadStoryEntity[]) => {
        return stories.map((entity: ReadStoryEntity) => {
          const story: ReadStoryEntity = {
            id: entity.id,
            ownerId: entity.ownerId,
            title: entity.title,
            price: entity.price,
            scoreEnable: entity.scoreEnable,
            scoreVisible: entity.scoreVisible,
            scoreUnit: entity.scoreUnit,
            scoreName: entity.scoreName,
            initScore: entity.initScore,
            thumbnailImage: entity.thumbnailImage,
            chapterList: entity.chapterList,
            billingChapterId: entity.billingChapterId,
            numberOfFreePlays: entity.numberOfFreePlays,
            published: entity.published,
            delete: entity.delete,
            titleVisible: entity.titleVisible,
            created: entity.created,
            updated: entity.updated,
          };
          return story;
        });
      })
    );
  }
}
