import React, { useEffect } from "react";
import styles from "./page.module.scss";
import { take } from "rxjs/operators";

import { useHistory } from "react-router";

import { AppContainer } from "../../../containers/app/app.container";

import { AuthService } from "@6cuts/auth/auth-service";
// import { FSReadOwnUserRepository } from "@6cuts/repository/read.own_user";
import { ReadOwnUserEntity } from "@6cuts/@dto/read.own-user";

/*
 * サインイン
 */
export const SignInTopPageComponent: React.FC = () => {
    const appContainer = AppContainer.useContainer()
    const history = useHistory()

    useEffect(() => {
        AuthService.checkRedirectResult()
            .then((user: firebase.User | null) => {
                if (user === null) { return }
                // FSReadOwnUserRepository.watchUser({ userId: user.uid })
                //     .pipe(take(1))
                //     .subscribe((userEntity: ReadOwnUserEntity | null) => {
                //         console.log(userEntity)
                //         if (userEntity !== null) {
                //             // アカウントがあればトップへ
                //             window.alert("move to top")
                //             appContainer.setUser(userEntity);
                //             history.push("/top");
                //         } else {
                //             // アカウントが存在しなければ作成画面へ
                //             window.alert("move to edit")
                //             history.push("/account/edit");
                //         }
                //     });

            })
    })

    const onClickTwitterSignInHandler = () => {
        AuthService.signInWithTwitter()
            .catch((error) => {
                window.alert(error.message)
            })
    }

    const onClickEmailSignInHandler = () => {
        history.push("/sign-in/email")
    }

    return (
        <div className={styles.page}>
            <div className="black-mask"></div>
            <div className="logo">
                <img src={`${process.env.PUBLIC_URL}/assets/images/6cuts_creator_topicon.png`}></img>
            </div>

            <div className="sign-in">
                <div className="social">
                    <button className="twitter button is-rounded" onClick={onClickTwitterSignInHandler}>
                        <figure className="image is-24x24">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/twitter_icon.svg`}></img>
                        </figure>
                        <span className="text">Twitter でログイン</span>
                    </button>
                </div>
                <div className="email">
                    <button className="email button is-text is-small has-text-white" onClick={onClickEmailSignInHandler}>メールアドレスでログイン</button>
                </div>
            </div>
        </div>
    );
};
