import React, { useState, useEffect, useRef } from "react";
import styles from "./page.module.scss";

import * as firebase from 'firebase'

import { AppContainer } from "../../../containers/app/app.container";
import { useHistory } from "react-router";
import { VideoCellComponent } from "./video-cell";
import { CSUserVideoRepository } from "@6cuts/storage/users/video";
import { UserMediaEntity } from "@6cuts/@dto/write.user";
import { FSReadOwnUserMediaRepository } from "@6cuts/public-repository/read.own_user.media";
import { FSWriteUserMediaRepository } from "@6cuts/public-repository/write.user.media";


export enum VideoLibraryStyle {
    browse = "browse",
    singleSelect = "single select",
    multipleSelect = "multiple select",
}

interface VideoLibraryPageComponentProps {
    style: VideoLibraryStyle,
    isEditMode: boolean,
    onSelectMediaListHandler?: (mediaList: UserMediaEntity[]) => void
}

/*
 * サインイン
 */
export const VideoLibraryPageComponent: React.FC<VideoLibraryPageComponentProps> = (props: VideoLibraryPageComponentProps) => {
    const appContainer = AppContainer.useContainer();
    const history = useHistory();

    // メディア一覧
    const [userMediaList, setUserMediaList] = useState<UserMediaEntity[]>([])

    // 選択中のメディア一覧
    const [selectedMediaList, setSelectedMediaList] = useState<UserMediaEntity[]>([])

    // アイコン画像関連
    const inputFileElement = useRef<HTMLInputElement>(null)

    // ローディング
    const [isProcessing, setIsProcessing] = useState<boolean>(false)
    const [loadingMessage, setLoadingMessage] = useState<string>("")

    useEffect(() => {
        if (appContainer.userId === null) {
            return
        }
        const subscription = FSReadOwnUserMediaRepository.watchList({
            userId: appContainer.userId
        }).subscribe((entityList: UserMediaEntity[]) => {
            const now = firebase.firestore.Timestamp.now()

            // TODO: なんかいい方法ないものか
            // 更新時は同期するとカラータグがめちゃめちゃになるので更新しない
            if (entityList.length === userMediaList.length) {
                return
            }

            setUserMediaList(
                entityList.sort((a, b) => {
                    if (a.created > b.created) return -1;
                    if (a.created < b.created) return 1;
                    return 0;
                }).filter((entity: UserMediaEntity) => {
                    // 準備中で300秒以上前のものは非表示
                    const diff = now.toDate().getTime() - entity.created.toDate().getTime()
                    return !(entity.status === "prepare" && diff / 1000 > 300)
                })
            )

            // 準備中で300秒以上前のものを削除
            const unnecessaryEntityList: UserMediaEntity[] = entityList.filter((entity: UserMediaEntity) => {
                const diff = now.toDate().getTime() - entity.created.toDate().getTime()
                return (entity.status === "prepare" && diff / 1000 > 300)
            })
            unnecessaryEntityList.forEach((entity: UserMediaEntity) => {
                if (appContainer.userId !== null) {
                    FSWriteUserMediaRepository.delete({
                        userId: appContainer.userId,
                        mediaId: entity.id,
                    })
                }
            })
        })

        return () => {
            console.log("unsubscribe!!")
            subscription.unsubscribe()
        }
    }, [])

    const onClickCreateNewVideoButtonHandler = () => {
        console.log("click")
        inputFileElement?.current?.click()
    }

    // -----------------------------------------------------
    //
    //  動画セルのイベントコールバック
    //
    // -----------------------------------------------------
    /**
     * メディアのコメントやカラータグ等のデータが変更された時のコールバック
     * @param media 
     */
    const onUpdateMediaDataHandler = (media: UserMediaEntity) => {
        const _mediaList: UserMediaEntity[] = userMediaList.map((_media: UserMediaEntity) => {
            return media.id === _media.id ? media : _media
        })
        setUserMediaList(_mediaList)
    }

    /**
     * メディアを削除したときのコールバック
     * @param media 
     */
    const onDeleteMediaHandler = (media: UserMediaEntity) => {
        const _mediaList: UserMediaEntity[] = userMediaList.filter((_media: UserMediaEntity) => {
            return media.id !== _media.id
        })
        setUserMediaList(_mediaList)
    }

    /**
     * メディアを選択した時のアクション
     * SingleSelectスタイルであれば一つだけセット
     * MultipleSelectスタイルであれば一覧に追加
     * @param media 
     */
    const onSelectMediaHandler = (media: UserMediaEntity) => {
        let mediaList: UserMediaEntity[] = []
        if (props.style === VideoLibraryStyle.singleSelect) {
            mediaList = [media]
        } else if (props.style === VideoLibraryStyle.multipleSelect) {
            const exist: boolean = selectedMediaList.some((_media: UserMediaEntity) => {
                return media.id === _media.id
            })
            if (exist) {
                mediaList = selectedMediaList.filter((_media: UserMediaEntity) => {
                    return media.id !== _media.id
                })
            } else {
                mediaList = [...selectedMediaList, media]
            }
        }
        if (props.onSelectMediaListHandler) { props.onSelectMediaListHandler(mediaList) }
        setSelectedMediaList(mediaList)
    }

    /**
     * アイコン画像が変更された時の処理
     * @param event 
     */
    const onChangeVideoHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files !== null && event.target.files.length > 0) {
            if (appContainer.userId === null) {
                return
            }

            if (event.target.files === null) {
                return
            }

            setIsProcessing(true)
            setLoadingMessage("now uploading...")

            let fileList: File[] = []
            for (let index = 0; index < event.target.files.length; index++) {
                fileList.push(event.target.files[index])
            }

            let newMediaList: UserMediaEntity[] = []
            for (let index = 0; index < fileList.length; index++) {
                const file: File = fileList[index]

                const mediaId: string = await FSWriteUserMediaRepository.create({
                    userId: appContainer.userId,
                    fileName: file.name,
                })
                    .catch((error) => {
                        console.log(error)
                        setIsProcessing(false)
                        // TODO: error message
                        throw error
                    })

                await CSUserVideoRepository.upload({
                    userId: appContainer.userId,
                    mediaId: mediaId,
                    file: file
                })
                    .catch((error) => {
                        setIsProcessing(false)
                        // TODO: error message
                    })

                const now = firebase.firestore.Timestamp.now()

                const newMedia: UserMediaEntity = {
                    id: mediaId,
                    userId: appContainer.userId,
                    status: "prepare",
                    memo: "",
                    color: "red",
                    duration: 0,
                    created: now,
                    updated: now,
                }

                newMediaList.push(newMedia)
            }

            setUserMediaList(
                userMediaList.concat(newMediaList).sort((a, b) => {
                    if (a.created > b.created) return -1;
                    if (a.created < b.created) return 1;
                    return 0;
                })
            )

            setIsProcessing(false)
            setLoadingMessage("")

        } else {
            setIsProcessing(false)
            setLoadingMessage("")
        }
    }

    return (
        <div className={styles.page}>
            <div className="add-video">
                <figure className="image" onClick={onClickCreateNewVideoButtonHandler}>
                    <i className="fas fa-cloud-upload-alt fa-2x"></i>
                </figure>
            </div>
            {
                userMediaList.map((media: UserMediaEntity, index: number) => {
                    return (
                        <div key={media.id} className="video-cell">
                            <VideoCellComponent
                                media={media}
                                isSelectEnabled={props.style !== VideoLibraryStyle.browse}
                                selected={selectedMediaList.some(m => m.id === media.id)}
                                isEditMode={props.isEditMode}
                                onUpdateMediaDataHandler={onUpdateMediaDataHandler}
                                onDeleteMediaDataHandler={onDeleteMediaHandler}
                                onSelectMediaHandler={onSelectMediaHandler}
                            />
                        </div>
                    )
                })
            }

            <input
                ref={inputFileElement}
                className="is-hidden"
                type="file"
                accept="video/*"
                multiple
                onChange={onChangeVideoHandler}
            />

            {isProcessing && (
                <div className="processing-mask">
                    <button className="loading button is-loading is-text"></button>
                    <div className="loading-message">{loadingMessage}</div>
                    <div className="notice-message">ブラウザを閉じないでください</div>
                </div>
            )}
        </div>
    );
};
