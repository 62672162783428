import * as firebase from "firebase"
import { v4 as uuidv4 } from "uuid";


export class CSUserVideoRepository {

    static directoryPath = (userId: string) => {
        return `users/${userId}`
    }

    /**
     * 動画サムネイルURLを取得
     * @param data 
     */
    static getThumbnailImage(
        data: {
            userId: string,
            mediaId: string,
        }
    ): Promise<string> {
        const ref = firebase.storage().ref(`${this.directoryPath(data.userId)}/${data.mediaId}_thumbnail`)
        return ref
            .getDownloadURL()
            .then((value: any) => {
                const url: string = value as string
                return url
            })
            .catch((error) => {
                console.log(error)
                throw error
            })
    }

    /**
     * 動画URLを取得
     * @param data 
     */
    static getVideoUrl(
        data: {
            userId: string,
            mediaId: string,
        }
    ): Promise<string> {
        const ref = firebase.storage().ref(`${this.directoryPath(data.userId)}/${data.mediaId}.mp4`)
        return ref
            .getDownloadURL()
            .then((value: any) => {
                const url: string = value as string
                return url
            })
            .catch((error) => {
                console.log(error)
                throw error
            })
    }

    /**
     * ユーザ動画を生成する
     * @param data 
     */
    static upload(
        data: {
            userId: string,
            mediaId: string,
            file: File,
        }
    ): Promise<boolean> {
        const ref = firebase.storage().ref(`${this.directoryPath(data.userId)}/${data.mediaId}`)
        return ref
            .put(data.file)
            .then((_) => {
                return true
            })
            .catch((error) => {
                console.log(error)
            })
    }
}