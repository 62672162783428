import React, { useState, useEffect } from "react";
import styles from "./chapter-page.module.scss";

import { useHistory, useParams } from "react-router-dom";

import { SentenceContainer } from "containers/story/sentence.container";
import { ChapterContainer } from "containers/story/chapter.container";
import { ChapterKeywordComponent } from "./action/keyword-action";
import { ChapterSelectComponent } from "./action/select-action";
import { FSReadStoryRepository } from "@6cuts/public-repository/read.story";
import {
  ReadStoryEntity,
  ReadChapter,
  ReadSentence,
} from "@6cuts/@dto/read.story";
import {
  ChapterActionType,
  ChapterActionSelect,
  ChapterActionKeyword,
  SentenceMediaType,
} from "@6cuts/@dto/write.story";
import { FSWriteStoryChapterRepository } from "@6cuts/public-repository/write.story.chapter";
import { FSWriteStorySentenceRepository } from "@6cuts/public-repository/write.story.sentence";
import { VideoLibrarySingleSelectPageComponent } from "components/video/library/single-select-page";
import { UserMediaEntity } from "@6cuts/@dto/write.user";
import { CSUserVideoRepository } from "@6cuts/storage/users/video";
import { AppContainer } from "containers/app/app.container";
import { LoadingModalComponent } from "components/common/loading/loading";

export const StoryChapterPageComponent: React.FC = () => {
  const { storyId } = useParams();
  const { chapterId } = useParams();

  const [story, setStory] = useState<ReadStoryEntity | null>(null);
  const [sentenceList, setSentenceList] = useState<ReadSentence[]>([]);
  const [chapter, setChapter] = useState<ReadChapter | null>(null);
  const [scoreEnable, setScoreEnable] = useState<boolean>(false);

  useEffect(() => {
    if (storyId === "") {
      return;
    }
    const subscription = FSReadStoryRepository.watchStory({
      storyId: storyId,
    }).subscribe((storyData: ReadStoryEntity | null) => {
      if (storyData !== null) {
        setStory(story);
        setScoreEnable(storyData.scoreEnable);
        storyData.chapterList.forEach((chapter: ReadChapter) => {
          if (chapter.chapterId === chapterId) {
            setChapter(chapter);
            setSentenceList(chapter.sentenceList);
          }
        });
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [storyId]);

  return (
    <ChapterContainer.Provider initialState={chapter}>
      <SentenceContainer.Provider initialState={sentenceList}>
        <StoryChapterComponent scoreEnable={scoreEnable} />
      </SentenceContainer.Provider>
    </ChapterContainer.Provider>
  );
};

interface StoryChapterProps {
  scoreEnable: boolean;
}

export const StoryChapterComponent: React.FC<StoryChapterProps> = (props) => {
  const { chapterId } = useParams();
  const { storyId } = useParams();

  const appContainer = AppContainer.useContainer();
  const sentenceContainer = SentenceContainer.useContainer();
  const chapterContainer = ChapterContainer.useContainer();
  const history = useHistory();
  const [isValid, setIsValid] = useState<boolean>(true);
  const [isReleaseLoading, setIsReleaseLoading] = useState<boolean>(false);

  /// サムネイル画像URL
  const [thumbnailImageUrl, setThumbnailImageUrl] = useState<string | null>(
    null
  );

  useEffect(() => {
    if (chapterContainer.actionValue === "") {
      return;
    }

    chapterContainer.resetActionData();

    let type = ChapterActionType.NONE;
    let count = Number(chapterContainer.actionValue.slice(-1));
    if (chapterContainer.actionValue.indexOf(ChapterActionType.SELECT) >= 0) {
      type = ChapterActionType.SELECT;
      createSelectComponentList({
        selectData: chapterContainer.selectData,
        count: count,
      });
    } else if (
      chapterContainer.actionValue.indexOf(ChapterActionType.KEYWORD) >= 0
    ) {
      type = ChapterActionType.KEYWORD;
      createKeywordComponentList({
        answerData: chapterContainer.keywordAnswerData,
        incorrectData: chapterContainer.keywordIncorrectData,
        count: count,
      });
    } else if (chapterContainer.actionValue === ChapterActionType.END) {
      type = ChapterActionType.END;
      chapterContainer.setActionComponentList([]);
    } else {
      console.log("actiontype none");
    }

    chapterContainer.setActionType(type);
  }, [chapterContainer.actionValue]);

  /**
   * メディアに応じてサムネイル画像取得
   */
  useEffect(() => {
    // null は + ボタン表示
    setThumbnailImageUrl(null);
    if (sentenceContainer.mediaId !== "" && appContainer.userId) {
      // "" はローディング表示
      setThumbnailImageUrl("");
      CSUserVideoRepository.getThumbnailImage({
        userId: appContainer.userId,
        mediaId: sentenceContainer.mediaId,
      }).then((url: string) => {
        // URLいれるとサムネ表示
        setThumbnailImageUrl(url);
      });
    }
  }, [sentenceContainer.mediaId]);

  //選択コンポーネントの作成
  const createSelectComponentList = (data: {
    selectData: ChapterActionSelect[];
    count: Number;
  }) => {
    let actionComponentlist = [];
    let actions = [];
    for (var i = 0; i < data.count; i++) {
      const action =
        data.selectData.length > i
          ? data.selectData[i]
          : FSWriteStoryChapterRepository.getActionSelectChapter();
      actionComponentlist.push(
        <ChapterSelectComponent
          selectNum={i + 1}
          action={action}
          capterId={chapterId}
          scoreEnable={props.scoreEnable}
        />
      );
      actions.push(action);
    }
    chapterContainer.setSelectData(actions);
    chapterContainer.setActionComponentList(actionComponentlist);
  };

  //キーワードコンポーネントの作成
  const createKeywordComponentList = (data: {
    answerData: ChapterActionKeyword[];
    incorrectData: ChapterActionKeyword;
    count: Number;
  }) => {
    let actionComponentlist = [];
    let actions = [];
    for (var i = 0; i < data.count; i++) {
      const asswerAction =
        data.answerData.length > i
          ? data.answerData[i]
          : FSWriteStoryChapterRepository.getActionKeywordChapter();
      actionComponentlist.push(
        <ChapterKeywordComponent
          isAnswer={true}
          keywordNum={i + 1}
          scoreEnable={props.scoreEnable}
          action={asswerAction}
          capterId={chapterId}
        />
      );
      actions.push(asswerAction);
    }
    const incorrect = data.incorrectData;
    actionComponentlist.push(
      <ChapterKeywordComponent
        isAnswer={false}
        action={incorrect}
        scoreEnable={props.scoreEnable}
        capterId={chapterId}
      />
    );
    chapterContainer.setKeywordAnswerData(actions);
    chapterContainer.setKeywordIncorrectData(incorrect);
    chapterContainer.setActionComponentList(actionComponentlist);
  };

  // 戻るボタンの処理
  const onClickBackPageHandler = () => {
    history.goBack();
  };

  // 保存ボタンの変更された時の処理
  const onClickSaveHandler = async () => {
    let saveChapter = chapterContainer.getChapter();
    let sentenceId = "";
    const valid = chapterContainer.checkChapter(saveChapter);
    setIsValid(valid);
    if (!valid) {
      return;
    }

    if (saveChapter.sentenceIdList.length === 0) {
      sentenceId = await FSWriteStorySentenceRepository.createSentence({
        storyId: storyId,
        sentence: sentenceContainer.getSentence(),
      });
    } else {
      await FSWriteStorySentenceRepository.updateSentence({
        storyId: storyId,
        sentence: sentenceContainer.getSentence(),
      });
    }

    if (chapterContainer.chapter === null) {
      saveChapter.sentenceIdList.push(sentenceId);
      FSWriteStoryChapterRepository.createChapter({
        storyId: storyId,
        chapter: saveChapter,
      });
    } else {
      await FSWriteStoryChapterRepository.updateChapter({
        storyId: storyId,
        chapter: saveChapter,
      });
    }

    setIsReleaseLoading(true);
  };

  //ビデオ選択ボタンの処理
  const onClickVideoHandler = () => {
    sentenceContainer.setIsVideoModal(true);
  };

  // 説明の変更された時の処理
  const onChangeExplanationHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value.length <= 40) {
      chapterContainer.setExplanation(event.target.value);
    }
  };

  //アクションのセレクターされた時の処理
  const onChangeActionSelectHandler = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    chapterContainer.setActionValue(event.target.value);
  };

  // --------------------------------------------------------------------
  //
  // VideoLibrarySingleSelectPageComponent Delegate
  //
  // --------------------------------------------------------------------
  /**
   * ビデオライブラリでメディアを決定した時のコールバック
   * @param media
   */
  const onSelectMediaOnVideoLibraryHandler = (media: UserMediaEntity) => {
    sentenceContainer.setIsVideoModal(false);
    sentenceContainer.setMediaInfo({
      mediaId: media.id,
      mediaType: SentenceMediaType.VIDEO,
    });
  };

  return (
    <div className={styles.page}>
      <div className="header">
        <div className="columns is-gapless is-mobile">
          <div className="column is-half">
            <label className="title">チャプター編集</label>
          </div>
          <div className="btn-list column">
            <button className="button" onClick={onClickBackPageHandler}>
              もどる
            </button>
            <button className="button" onClick={onClickSaveHandler}>
              保存
            </button>
          </div>
        </div>
      </div>
      <div className="container">
        {!isValid && (
          <div className="invalid has-text-danger">
            入力項目が正しくありません。
          </div>
        )}
        <div className="chapter-id">
          チャプターID:{("000" + chapterId).slice(-3)}
        </div>
        <div className="title is-5">動画</div>
        <div className="video-select" onClick={onClickVideoHandler}>
          {thumbnailImageUrl === null && (
            <figure className="image is-1by1">
              <i className="fas fa-plus"></i>
            </figure>
          )}
          {thumbnailImageUrl === "" && (
            <figure className="image is-1by1">
              <button className="loading button is-loading is-text"></button>
            </figure>
          )}
          {thumbnailImageUrl !== null && thumbnailImageUrl !== "" && (
            <img src={thumbnailImageUrl} />
          )}
        </div>
        <div className="title is-5">アクションテキスト</div>
        <div className="input-text">
          <input
            className="input"
            type="text"
            value={chapterContainer.explanation}
            onChange={onChangeExplanationHandler}
            maxLength={40}
          />
          <label>※上部に表示されるテキスト。最大40文字。</label>
        </div>
        <div className="title is-5">アクションを選択</div>
        <div className="action-select">
          <div className="select is-primary">
            <select
              value={chapterContainer.actionValue}
              onChange={(e) => onChangeActionSelectHandler(e)}
            >
              {chapterContainer.actionValue === "" && (
                <option value="">選択してください</option>
              )}
              <option value="select1">一択</option>
              <option value="select2">二択</option>
              <option value="select3">三択</option>
              <option value="select4">四択</option>
              <option value="select5">五択</option>
              <option value="select6">六択</option>
              <option value="keyword1">キーワード指定１</option>
              <option value="keyword2">キーワード指定２</option>
              <option value="keyword3">キーワード指定３</option>
              <option value="end">終了</option>
            </select>
          </div>
        </div>
      </div>
      <ul>{chapterContainer.actionComponentList}</ul>

      {sentenceContainer.isVideoModal && (
        <div className="video-select-modal">
          <VideoLibrarySingleSelectPageComponent
            onTapBackButtonHandler={() =>
              sentenceContainer.setIsVideoModal(false)
            }
            onSelectMediaHandler={onSelectMediaOnVideoLibraryHandler}
          />
        </div>
      )}
      <LoadingModalComponent
        title="保存中"
        isOpen={isReleaseLoading}
        second={3000}
        timerCallback={() => {
          history.goBack();
          setIsReleaseLoading(false);
        }}
      />
    </div>
  );
};
