import React, { useState, useEffect, useRef } from "react";
import styles from "./browse-page.module.scss";

import { AppContainer } from "../../../containers/app/app.container";
import { useHistory } from "react-router";
import { VideoLibraryPageComponent, VideoLibraryStyle } from "./page";


/*
 * サインイン
 */
export const VideoLibraryBrowsePageComponent: React.FC = () => {
    const appContainer = AppContainer.useContainer();
    const history = useHistory();

    const [isEditMode, setIsEditMode] = useState<boolean>(false)

    const onClickEditHandler = () => {
        setIsEditMode(!isEditMode)
    }

    return (
        <div className={styles.page}>
            <nav className="navbar is-primary" role="navigation" aria-label="main navigation">
                <div className="navbar-brand">
                    <a className="navbar-item" onClick={() => { history.push("/top") }}>
                        <figure className="image is-24x24 is-1by1">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/6cuts_top_icon.png`} />
                        </figure>
                    </a>
                    <span className="navbar-item has-text-white">動画ライブラリ</span>

                    {
                        !isEditMode && (
                            <button
                                className={`navbar-burger button is-white is-small is-outlined`}
                                onClick={onClickEditHandler}
                            >編集</button>
                        )
                    }
                    {
                        isEditMode && (
                            <button
                                className={`navbar-burger button is-white is-small is-outlined`}
                                onClick={onClickEditHandler}
                            >戻る</button>

                        )
                    }
                </div>
            </nav>

            <section className="section">
                <div className="container">
                    <VideoLibraryPageComponent
                        style={VideoLibraryStyle.browse}
                        isEditMode={isEditMode}
                    />
                </div>
            </section>
        </div>
    );
};
