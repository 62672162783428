import * as firebase from "firebase";

import { v4 as uuidv4 } from "uuid";
import { WriteEventEntity, EventType } from "@6cuts/@dto/write.event";

export class FSWriteEventRepository {
  static createStoryCloneEvent(data: {
    storyId: string;
    userId: string;
  }): Promise<string> {
    const now = firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp;
    const event: WriteEventEntity = {
      userId: data.userId,
      type: EventType.story.clone,
      data: {
        storyId: data.storyId,
      },
      created: now,
      updated: now,
    };

    return firebase
      .firestore()
      .collection("write.event")
      .add(event)
      .then(
        (
          ref: firebase.firestore.DocumentReference<
            firebase.firestore.DocumentData
          >
        ) => {
          return ref.id;
        }
      );
  }

  static createStoryFreePlayEvent(data: {
    storyId: string;
    playId: string;
  }): Promise<string> {
    const now = firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp;
    const event = {
      userId: "",
      type: EventType.story.play_count.freePlay,
      data: {
        storyId: data.storyId,
        playId: data.playId,
      },
      created: now,
      updated: now,
    };

    return firebase
      .firestore()
      .collection("write.event")
      .add(event)
      .then(
        (
          ref: firebase.firestore.DocumentReference<
            firebase.firestore.DocumentData
          >
        ) => {
          return ref.id;
        }
      );
  }

  static createStoryPlayEvent(data: {
    storyId: string;
    playId: string;
  }): Promise<string> {
    const now = firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp;
    const event = {
      userId: "",
      type: EventType.story.play_count.play,
      data: {
        storyId: data.storyId,
        playId: data.playId,
      },
      created: now,
      updated: now,
    };

    return firebase
      .firestore()
      .collection("write.event")
      .add(event)
      .then(
        (
          ref: firebase.firestore.DocumentReference<
            firebase.firestore.DocumentData
          >
        ) => {
          return ref.id;
        }
      );
  }

  static createStoryPurchase(data: {
    storyId: string;
    playId: string;
  }): Promise<string> {
    const now = firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp;
    const event = {
      userId: "",
      type: EventType.story.play_count.purchase,
      data: {
        storyId: data.storyId,
        playId: data.playId,
      },
      created: now,
      updated: now,
    };

    return firebase
      .firestore()
      .collection("write.event")
      .add(event)
      .then(
        (
          ref: firebase.firestore.DocumentReference<
            firebase.firestore.DocumentData
          >
        ) => {
          return ref.id;
        }
      );
  }
}
