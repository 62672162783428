import React, { useState, useEffect } from "react";
import styles from "./alert.module.scss";

interface YesNoAlertProps {
  title: string;
  message: string;
  yesText?: string;
  noText?: string;
  yesCallback: () => void;
  noCallback: () => void;
  isOpen: boolean;
}

export const YesNoAlertComponent: React.FC<YesNoAlertProps> = (
  props: YesNoAlertProps
) => {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(props.isOpen);
  }, [props.isOpen]);

  const messageDOM = (text: string | undefined) => {
    if (text === undefined) {
      return "";
    }

    const regex = /(\n)/g;

    return text.split(regex).map(function (line) {
      return <p>{line}</p>;
    });
  };

  if (open) {
    return (
      <div className={styles.alert}>
        <div className="modal is-active">
          <div className="modal-background"></div>
          <div className="modal-content">
            <div className="dialog">
              <div className="title-dialog">{props.title}</div>
              <div className="message-dialog">{messageDOM(props.message)}</div>
              <div className="button-dialog">
                <div className="columns is-mobile is-gapless">
                  <div
                    className="button-cancel column is-half has-text-link has-text-centered"
                    onClick={props.noCallback}
                  >
                    {props.noText !== undefined ? props.noText : "Cancel"}
                  </div>
                  <div
                    className="button-ok column is-half has-text-link has-text-centered"
                    onClick={props.yesCallback}
                  >
                    {props.yesText !== undefined ? props.yesText : "OK"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

interface OkAlertProps {
  title: string;
  message: string;
  okText?: string;
  okCallback: () => void;
  isOpen: boolean;
}

export const OkAlertComponent: React.FC<OkAlertProps> = (
  props: OkAlertProps
) => {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(props.isOpen);
  }, [props.isOpen]);

  const messageDOM = (text: string | undefined) => {
    if (text === undefined) {
      return "";
    }

    const regex = /(\n)/g;

    return text.split(regex).map(function (line) {
      return <p>{line}</p>;
    });
  };

  if (open) {
    return (
      <div className={styles.alert}>
        <div className="modal is-active">
          <div className="modal-background"></div>
          <div className="modal-content">
            <div className="dialog">
              <div className="title-dialog">{props.title}</div>
              <div className="message-dialog">{messageDOM(props.message)}</div>
              <div className="button-dialog">
                <div
                  className="button-ok column is-half has-text-link has-text-centered"
                  onClick={props.okCallback}
                >
                  {props.okText !== undefined ? props.okText : "OK"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

interface InputAlertProps {
  title: string;
  message: string;
  yesText?: string;
  noText?: string;
  placeholder?: string;
  defaultText?: string;
  yesCallback: (text: string) => void;
  noCallback: () => void;
  isOpen: boolean;
}

export const InputAlertComponent: React.FC<InputAlertProps> = (
  props: InputAlertProps
) => {
  const [text, setText] = useState(
    props.defaultText !== undefined ? props.defaultText : ""
  );
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(props.isOpen);
  }, [props.isOpen]);

  const messageDOM = (text: string | undefined) => {
    if (text === undefined) {
      return "";
    }

    const regex = /(\n)/g;

    return text.split(regex).map(function (line) {
      return <p>{line}</p>;
    });
  };

  const onChangeTextAreaHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  };

  if (open) {
    return (
      <div className={styles.alert}>
        <div className="modal is-active">
          <div className="modal-background"></div>
          <div className="modal-content">
            <div className="dialog">
              <div className="title-dialog">{props.title}</div>
              <div className="message-dialog">{messageDOM(props.message)}</div>
              <div className="text-input">
                <input
                  className="input"
                  type="text"
                  placeholder={
                    props.placeholder !== undefined ? props.placeholder : ""
                  }
                  defaultValue={
                    props.defaultText !== undefined ? props.defaultText : ""
                  }
                  onChange={onChangeTextAreaHandler}
                />
              </div>

              <div className="button-dialog">
                <div className="columns is-mobile is-gapless">
                  <div
                    className="button-cancel column is-half has-text-link has-text-centered"
                    onClick={props.noCallback}
                  >
                    {props.noText !== undefined ? props.noText : "Cancel"}
                  </div>
                  <div
                    className="button-ok column is-half has-text-link has-text-centered"
                    onClick={() => props.yesCallback(text)}
                  >
                    {props.yesText !== undefined ? props.yesText : "OK"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};



interface TextAreaAlertProps {
  title: string;
  message: string;
  yesText?: string;
  noText?: string;
  placeholder?: string;
  defaultText?: string;
  yesCallback: (text: string) => void;
  noCallback: () => void;
  isOpen: boolean;
}
export const TextAreaAlertComponent: React.FC<TextAreaAlertProps> = (
  props: TextAreaAlertProps
) => {
  const [text, setText] = useState(
    props.defaultText !== undefined ? props.defaultText : ""
  );
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(props.isOpen);
  }, [props.isOpen]);

  const messageDOM = (text: string | undefined) => {
    if (text === undefined) {
      return "";
    }

    const regex = /(\n)/g;

    return text.split(regex).map(function (line) {
      return <p>{line}</p>;
    });
  };

  const onChangeTextAreaHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
  };

  if (open) {
    return (
      <div className={styles.alert}>
        <div className="modal is-active">
          <div className="modal-background"></div>
          <div className="modal-content">
            <div className="dialog">
              <div className="title-dialog">{props.title}</div>
              <div className="message-dialog">{messageDOM(props.message)}</div>
              <div className="text-input">
                <textarea
                  className="input"
                  placeholder={
                    props.placeholder !== undefined ? props.placeholder : ""
                  }
                  defaultValue={
                    props.defaultText !== undefined ? props.defaultText : ""
                  }
                  onChange={onChangeTextAreaHandler}
                />
              </div>

              <div className="button-dialog">
                <div className="columns is-mobile is-gapless">
                  <div
                    className="button-cancel column is-half has-text-link has-text-centered"
                    onClick={props.noCallback}
                  >
                    {props.noText !== undefined ? props.noText : "Cancel"}
                  </div>
                  <div
                    className="button-ok column is-half has-text-link has-text-centered"
                    onClick={() => props.yesCallback(text)}
                  >
                    {props.yesText !== undefined ? props.yesText : "OK"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};
