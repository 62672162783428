import React, { useState, useEffect } from "react";
import styles from "./chapter-list-page.module.scss";
import { useHistory, useParams } from "react-router-dom";

import { AppContainer } from "containers/app/app.container";
import { ChapterCellComponent } from "./chapter-cell";
import { FSReadStoryRepository } from "@6cuts/public-repository/read.story";
import { ReadStoryEntity, ReadChapter } from "@6cuts/@dto/read.story";
import { getOwnUrl } from "../../../../tool/url";
import { VideoPreviewComponent } from "components/video/library/video-preview";

export const getMaxChapterNumber = () => {
  const MAX_CHAPTER_NUM = 100;
  return MAX_CHAPTER_NUM;
};

export const StoryChapterListPageComponent: React.FC = () => {
  const history = useHistory();
  const { storyId } = useParams();
  const [chaperCellComponents, setChaperCellComponents] = useState<
    JSX.Element[]
  >([]);
  const appContainer = AppContainer.useContainer();

  // チャプタービデオ表示フラグ
  const [
    chapterMediaForPreview,
    setChapterMediaForPreview,
  ] = useState<ReadChapter | null>(null);

  useEffect(() => {
    if (storyId === "") {
      return;
    }
    const subscription = FSReadStoryRepository.watchStory({
      storyId: storyId,
    }).subscribe((storyData: ReadStoryEntity | null) => {
      if (storyData !== null) {
        createList(storyData.chapterList);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [storyId]);

  const createList = (chapterList: ReadChapter[]) => {
    let conmponents = [];
    let chapters = [];
    for (var i = 0; i < getMaxChapterNumber(); i++) {
      const filterList: ReadChapter[] = chapterList.filter(
        (chapterData: ReadChapter) => {
          return chapterData.chapterId === (i + 1).toString();
        }
      );
      const targetChapter: ReadChapter | null =
        filterList.length > 0 ? filterList[0] : null;

      chapters.push(targetChapter);
      conmponents.push(
        <ChapterCellComponent
          key={i}
          userId={appContainer.userId !== null ? appContainer.userId : ""}
          storyId={storyId}
          number={i + 1}
          chapterData={targetChapter}
          chapters={chapters}
          onClickVideoPreviewHandler={onClickVideoPreviewHandler}
        />
      );
    }
    setChaperCellComponents(conmponents);
  };

  const onClickBackPageHandler = () => {
    history.goBack();
  };

  const onClickPreviewHandler = () => {
    // isLocalhost;
    console.log(getOwnUrl());
    window.open(`${getOwnUrl()}/story/edit/preview/${storyId}/`);
  };

  const onClickVideoPreviewHandler = (chapter: ReadChapter) => {
    console.log(chapter);
    setChapterMediaForPreview(chapter);
  };

  const onClickCloseChapterVideoPreviewModalHandler = () => {
    setChapterMediaForPreview(null);
  };

  return (
    <div className={styles.page}>
      <div className="header">
        <div className="columns is-gapless is-mobile">
          <div className="column is-half">
            <label className="title">チャプターリスト</label>
          </div>
          <div className="btn-list column">
            <button className="button" onClick={onClickBackPageHandler}>
              もどる
            </button>
            <button className="button" onClick={onClickPreviewHandler}>
              プレビュー
            </button>
          </div>
        </div>
      </div>
      <div className="container">
        <ul>{chaperCellComponents}</ul>
      </div>
      {chapterMediaForPreview !== null && (
        <VideoPreviewComponent
          userId={appContainer.userId!}
          mediaId={chapterMediaForPreview.sentenceList[0].mediaId}
          memo={""}
          onCloseHandler={onClickCloseChapterVideoPreviewModalHandler}
        />
      )}
    </div>
  );
};
