import React from "react";
import styles from "./page.module.scss";
import { useHistory } from "react-router";

/*
 * サインイン
 */
export const LoadingPageComponent: React.FC = () => {
    const history = useHistory();

    return (
        <div className={styles.page}>
            <div className="black-mask"></div>
            <div className="logo">
                <img src={`${process.env.PUBLIC_URL}/assets/images/6cuts_creator_topicon.png`}></img>
                <button className="loading button is-loading is-text">Now Loading...</button>
            </div>
        </div>
    );
};
