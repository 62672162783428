import React, { useState, useEffect } from "react";
import styles from "./loading.module.scss";

interface LoadingProps {
  title: string;
  isOpen: boolean;
  second?: number;
  timerCallback?: () => void;
}

export const LoadingModalComponent: React.FC<LoadingProps> = (
  props: LoadingProps
) => {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(props.isOpen);

    if (props.isOpen) {
      //時間指定があれば自動で閉じる
      if (props.second && props.second > 0) {
        const timer = setTimeout(() => {
          setOpen(false);
          if (props.timerCallback) {
            props.timerCallback();
          }
        }, props.second);
        return () => clearTimeout(timer);
      }
    }
  }, [props.isOpen]);

  const messageDOM = (text: string | undefined) => {
    if (text === undefined) {
      return "";
    }

    const regex = /(\n)/g;

    return text.split(regex).map(function (line) {
      return <p>{line}</p>;
    });
  };

  if (open) {
    return (
      <div className={styles.loding}>
        <div className="modal is-active">
          <div className="modal-background"></div>
          <div className="modal-content">
            <div className="has-text-white">{messageDOM(props.title)}</div>
            <button className="loading button is-loading is-text is-large">
              Now Loading...
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};
