import React, { useState } from "react";
import "./story-keyword.component.scss";
import { StoryContainer } from "../../story.container";
import { ChapterKeyWord } from "@6cuts/@dto/write.story";

interface StoryKeywordProps {
  keywordList: ChapterKeyWord;
}

export const StoryKeywordComponent: React.FC<StoryKeywordProps> = (
  props: StoryKeywordProps
) => {
  const storyContainer = StoryContainer.useContainer();

  //Hooksを使ってinputをハンドリング
  const [keyword, setKeyword] = useState("");
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.target.value);
  };

  const onClickScreen = () => {
    storyContainer.switchFullScreen();
  };

  const onClickInput = (event: React.MouseEvent<HTMLInputElement>) => {
    event.stopPropagation();
  };

  const onClicksDecisionButton = () => {
    console.log(props.keywordList);
    //TO DO なにか入力してくださいとか出す
    if (keyword == "") {
      return;
    }
    let isCorrect = false;
    //正解
    props.keywordList.answer.forEach((data: { [index: string]: any }) => {
      if (data.text == keyword) {
        storyContainer.moveToNextChapter(data.transitionId);
        storyContainer.addScorePoint(data.point);
        storyContainer.playScorePoint(data.point);
        isCorrect = true;
      }
    });
    //不正解
    if (!isCorrect) {
      storyContainer.moveToNextChapter(props.keywordList.incorrect.transitionId);
      storyContainer.addScorePoint(props.keywordList.incorrect.point);
      storyContainer.playScorePoint(props.keywordList.incorrect.point);
    }
  };

  return (
    <div className="keyword-wrapper" onClick={onClickScreen}>
      {!storyContainer.isFullScreen && (
        <div className="keyword">
          <input
            name="title"
            className="input-keyword input"
            type="text"
            placeholder="カタカナで入力"
            onChange={handleChange}
            onClick={onClickInput}
          />

          <button
            className="button-decision button is-light"
            disabled={keyword == ""}
            onClick={onClicksDecisionButton}
          >
            確定
          </button>
        </div>
      )}
    </div>
  );
};
