import React, { useState, useEffect, useRef } from "react";
import styles from "./page.module.scss";
import { AppContainer } from "containers/app/app.container";
import { useHistory } from "react-router";
import { CSUserImageRepository } from "@6cuts/storage/users/image";
import { ReadOwnUserEntity } from "@6cuts/@dto/read.own-user";
import { FSReadOwnUserRepository } from "@6cuts/public-repository/read.own_user";
import { Gender } from "@6cuts/@dto/write.user"
import { FSWriteUserRepository } from "@6cuts/public-repository/write.user";

interface User {
    id: string
    nickname: string
    isExistIcon: boolean
    gender: string
    birthday: string
}


/*
 * サインイン
 */
export const AccountEditPageComponent: React.FC = () => {
    const appContainer = AppContainer.useContainer();
    const history = useHistory();

    const user: User = appContainer.user !== null ?
        appContainer.user
        :
        {
            id: appContainer.userId!,
            nickname: "",
            gender: Gender.unknown,
            birthday: "",
            isExistIcon: false,
        }

    // アイコン画像関連
    const [iconFile, setIconFile] = useState<File | null>(null)
    const [iconFilePreviewData, setIconFilePreviewData] = useState<string | undefined>(undefined)
    const inputFileElement = useRef<HTMLInputElement>(null)

    // ニックネーム
    const [nickname, setNickname] = useState<string>(user.nickname)

    // 性別
    const [gender, setGender] = useState<string>(user.gender)

    // 誕生日
    const [birthday, setBirthday] = useState<string>(user.birthday)
    const defaultBirthday: string = user.birthday === "" ? "" : `${user.birthday.slice(0, 4)}-${user.birthday.slice(4, 6)}-${user.birthday.slice(6, 8)}`

    // ローディング
    const [isSaving, setIsSaving] = useState<boolean>(false)

    // エラーメッセージ
    const [errorMessage, setErrorMessage] = useState<string>("")

    useEffect(() => {
        if (user.isExistIcon === true) {
            CSUserImageRepository.getUserThumbnailImageUrl({
                userId: user.id
            })
                .then((imageUrl: string) => {
                    console.log(imageUrl)
                    setIconFilePreviewData(imageUrl)
                })
        }
    }, [])

    const onClickImageButtonHandler = () => {
        console.log("click")
        inputFileElement?.current?.click()
    }

    /**
     * アイコン画像が変更された時の処理
     * @param event 
     */
    const onChangeIconFileHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files !== null && event.target.files.length > 0) {
            const file: File = event.target.files[0]
            setIconFile(file)

            const reader = new FileReader()
            reader.onload = (e) => {
                if (e.target?.result === undefined) {
                    setIconFilePreviewData(undefined)
                } else {
                    setIconFilePreviewData(e.target?.result as string)
                }
            };
            reader.readAsDataURL(file)
        } else {
            setIconFile(null)
            setIconFilePreviewData(undefined)
        }
    }

    /**
     * ニックネームが変更された時の処理
     * @param event 
     */
    const onChangeNicknameHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNickname(event.target.value);
    }

    /**
     * 性別が変更された時の処理
     * @param event 
     */
    const onChangeGenderHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGender(event.target.value);
    }

    const onChangeBirthdayHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.value.split("-").join(""))
        setBirthday(event.target.value.split("-").join(""))
    }

    /**
     * 保存ボタン押下時の処理
     * 画像アップロード + データ更新
     */
    const onSaveHandler = async () => {
        setErrorMessage("")
        setIsSaving(true)

        // アップロードする画像があればアップロード
        if (iconFile !== null) {
            const result: boolean = await CSUserImageRepository.upload({
                userId: user.id,
                file: iconFile,
            })
            if (result === false) {
                setIsSaving(false)
                setErrorMessage("failed to upload image. please check network communication and retry.")
                return
            }
        }

        // データの更新
        FSWriteUserRepository.create({
            userId: user.id,
            nickname: nickname,
            gender: gender,
            birthday: birthday,
            isExstIcon: true
        }).then(() => {
            const subscription = FSReadOwnUserRepository.watchUser({
                userId: user.id
            }).subscribe((userEntity: ReadOwnUserEntity | null) => {
                if (userEntity === null) {
                    return
                }

                subscription.unsubscribe()
                setIsSaving(false)
                history.push("/top")
            })

        }).catch((error) => {
            setIsSaving(false)
            setErrorMessage("failed to save your data. please check network communication and retry.")
        })
    }

    /**
     * 保存ボタン押せるかどうか
     * ニックネームと写真を必須にする
     */
    const saveButtonEnabled: boolean = nickname !== "" && !(iconFile === null && user.isExistIcon === false)

    return (
        <div className={styles.page}>
            <nav className="navbar is-primary" role="navigation" aria-label="main navigation">
                <div className="navbar-brand">
                    <a className="navbar-item" onClick={() => { history.push("/top") }}>
                        <figure className="image is-24x24 is-1by1">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/6cuts_top_icon.png`} />
                        </figure>
                    </a>
                    <span className="navbar-item has-text-white">アカウント設定</span>
                </div>
            </nav>

            <section className="section">
                <div className="container">

                    <div className="field is-vertical">
                        <div className="field-label is-normal">
                            <label className="label">アイコン画像 (※)</label>
                        </div>
                        <div className="field-body" onClick={onClickImageButtonHandler}>
                            {user.isExistIcon === false && iconFile === null && (
                                <i className="far fa-image fa-5x"></i>
                            )}
                            {iconFilePreviewData !== undefined && (
                                <figure className="image">
                                    <img className="is-rounded preview" src={iconFilePreviewData} />
                                </figure>
                            )}
                        </div>
                    </div>

                    <div className="field is-vertical">
                        <div className="field-label is-normal">
                            <label className="label">ニックネーム (※)</label>
                        </div>
                        <div className="field-body">
                            <div className="field">
                                <p className="control">
                                    <input
                                        className="input"
                                        type="email"
                                        placeholder="Email"
                                        onChange={onChangeNicknameHandler}
                                        defaultValue={nickname}
                                    />
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="field is-vertical">
                        <div className="field-label is-normal">
                            <label className="label">性別</label>
                        </div>
                        <div className="field-body">
                            <div className="field">
                                <div className="control">
                                    <label className="radio">
                                        <input
                                            type="radio"
                                            name="answer"
                                            value={Gender.male}
                                            onChange={onChangeGenderHandler}
                                            checked={gender === Gender.male}
                                        />
                                        男性
                                    </label>
                                    <label className="radio">
                                        <input
                                            type="radio"
                                            name="answer"
                                            value={Gender.female}
                                            onChange={onChangeGenderHandler}
                                            checked={gender === Gender.female}
                                        />
                                        女性
                                    </label>
                                    <label className="radio">
                                        <input
                                            type="radio"
                                            name="answer"
                                            value={Gender.unknown}
                                            onChange={onChangeGenderHandler}
                                            checked={gender === Gender.unknown}
                                        />
                                        回答しない
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="field is-vertical">
                        <div className="field-label is-normal">
                            <label className="label">生年月日</label>
                        </div>
                        <div className="field-body">
                            <div className="field">
                                <p className="control">
                                    <input
                                        className="input"
                                        type="date"
                                        defaultValue={defaultBirthday}
                                        onChange={onChangeBirthdayHandler}
                                    />
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="error-message has-text-danger">{errorMessage}</div>
                    <div className="action">
                        <div>
                            <button
                                className="button is-outlined sign-in"
                                disabled={!saveButtonEnabled}
                                onClick={onSaveHandler}
                            >
                                保存
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            <input
                ref={inputFileElement}
                className="is-hidden"
                type="file"
                accept="image/*"
                onChange={onChangeIconFileHandler}
            />

            {isSaving && (
                <div className="saving-mask">
                    <button className="loading button is-loading is-text"></button>
                </div>
            )}
        </div>
    );
};
